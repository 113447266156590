import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { NotAvailable } from '../NotAvailable';
import { getUrlLink, formatWebsite, formatRevenue, formatNumber, formatDate, provideLinkToCompanyPage, formatText, asteriskHoverover } from './Util';
import { OverlayTooltip } from '../OverlayTooltip';
import questionMark from '../../images/questionmark-icon.png';
import salesforce from '../../images/salesforce-logo.png';
import { EmployeeDetails } from './EmployeeDetails';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

export const CompanyDescription = () => {
    const { account, followingAccounts, followAccount, isProspectPage } = useContext(GlobalContext);
    const [clientSideSettings] = useState(JSON.parse(localStorage.getItem('ClientSideSettings')));
    const [showMoreChild, setShowMoreChild] = useState(false);

    const isFollowing = (id) => {
        const followingId = followingAccounts.map(_ => _.id);
        return (
            <div className='follow-button-margin'>
                <Button className={followingId.includes(id) ? 'following-active' : 'following-inactive'} onClick={() => followAccount(id)}>
                    {followingId.includes(id) ? 'Following' : 'Follow'}
                </Button>
            </div>
        );
    }

    const checkAccountType = (accountType) => {
        return accountType && (accountType === 'Key' || accountType === 'Strategic');
    }

    const renderChildParent = (id, name) => {
        return (
            provideLinkToCompanyPage(id) ?
                <Card.Link href={`/company/${id}`} className='text-primary'>{name}</Card.Link>
                :
                <span>{name}</span>    
        );
    }

    const iconChoice = (item) => {
        if (item.substring(0, item.indexOf('.com')).includes('linkedin')) {
            return <LinkedInIcon />
        }
        else if (item.substring(0, item.indexOf('.com')).includes('twitter')) {
            return <TwitterIcon style={{ fill: 'deepskyblue' }} />
        }
        else if (item.substring(0, item.indexOf('.com')).includes('youtube')) {
            return <YouTubeIcon style={{ fill: 'red' }} />
        }
        else if (item.substring(0, item.indexOf('.com')).includes('facebook')) {
            return <FacebookIcon />
        }
        else {
            return null;
        }

    }

    const urlMap = (urls) => {
        return (
            <span>
            {
                urls && urls.length !== 0 ?
                    urls.map((item, i) => {
                        if (iconChoice(item) != null) {
                            return (
                                <Button className='hubBtn' style={{ float: 'right', marginBottom: '15px', fontSize: '90%', marginLeft: '10px', width: '94px' }} href={formatWebsite(item)} target='_blank' rel='noopener noreferrer' key={i}> {iconChoice(item)} </Button>
                            );
                        }
                    })
                    : null
            }
            </span>
        )
    }
    return (
        <Card>
            <Card.Body className='card-style' style={{ borderTopColor: "#00ACC4" }}>
                <div style={{ padding: '30px 30px 10px 30px' }}>
                    <div style={{ justifyContent: 'space-between', display: 'flex', flexWrap: 'wrap' }}>
                        <div style={{ marginBottom: '10px' }}>
                            <div style={{ display: 'inline-flex', marginTop: '30px'  }}>
                                {provideLinkToCompanyPage(account.id) && isProspectPage ?
                                    <Link to={`/company/${account.id}`}><h4 className='card-title-company-name' style={{ marginBottom: '0px' }}>{account.name}</h4></Link> :
                                    <h4 className='card-title-company-name' style={{ marginBottom: '0px' }}>
                                        {account.name}
                                    </h4>
                                }
                                {isFollowing(account.id)}
                            </div>       
                            {account.companyPhotoUrl ? <div className='company-picture' style={{ backgroundImage: `url(${account.companyPhotoUrl})`, marginLeft: '15px', float: 'right' }}></div> : null}
                            {checkAccountType(account.accountType) ?
                                <div className='prospect-fields'>
                                    <span style={{ color: '#f6871f' }}>{account.accountType} Account</span>
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className='prospect-fields' style={{ lineHeight: 2, marginLeft: 'auto', marginBottom: '0px' }}>
                            <div>
                                <span className='header-fields'>Parent Company: </span>
                                {account.accountParent ?
                                    <OverlayTooltip tooltipText='Note: See Parent Company Page for the most information' hoverElement={renderChildParent(account.accountParent.id, account.accountParent.name)} />
                                    :
                                    "Not Available/Not Applicable"
                                }
                            </div>
                        </div>
                    </div>

                    <p className='company-description'>
                        {
                            (account.description && account.description.length > 1200) ?
                                <OverlayTooltip tooltipText={account.description} hoverElement={<span>{formatText(account.description, 1200)}</span>} imageType='none' />
                                :
                                account.description
                        }
                    </p>
                    <div>
                        <Row>
                            <Col md>
                                <div className='prospect-fields'><span className='header-fields'>Headquarters: </span>{account.headQuarters ? account.headQuarters : <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningAcc', account.id)} />}</div>
                                <div className='prospect-fields'><span className='header-fields'>Industry: </span>{account.industry ? account.industry : <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningAcc', account.id)} />}</div>
                                <div className='prospect-fields'><span className='header-fields'>Sub Industry: </span>{account.subIndustry ? account.subIndustry : <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningAcc', account.id)} />}</div>
                                {account.ownership === 'Public' || account.ownership === null ?
                                    <div>
                                        <div className='prospect-fields'><span className='header-fields'>Ticker: </span>{account.tickerSymbol ? account.tickerSymbol : <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningAcc', account.id)} />}</div>
                                        <div className='prospect-fields'><span className='header-fields'>IPO Date: </span>{(account.ipoDate && account.ipoDate.substring(0, 4) !== '0001') ? formatDate(account.ipoDate) : <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningAcc', account.id)} />}</div>
                                    </div>
                                    :
                                    <div className='prospect-fields'><span className='header-fields'>Privately Held Company</span> <NotAvailable notavailable='ownership' linkUrl={getUrlLink('sfdclightningAcc', account.id)} /></div>

                                }
                                {!account.id ? 
                                    <div className='prospect-fields'>
                                        <span className='header-fields'>In Salesforce?: No</span>
                                    </div>
                                    :
                                    null
                                }
                            </Col>
                            <Col md lg={4}>
                                <div className='prospect-fields'><span className='header-fields'>Number of Employees: </span>{account.numberOfEmployees ? formatNumber(account.numberOfEmployees) : <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningAcc', account.id)} />}</div>
                                <div className='prospect-fields'><span className='header-fields'>Annual Revenue: </span> {account.annualRevenue ? formatRevenue(account.annualRevenue) : <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningAcc', account.id)} />}</div>
                                <div className='prospect-fields'><span className='header-fields'>Fortune Ranking: </span>{account.ranking ? account.ranking : <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningAcc', account.id)} />}</div>
                                <div className='prospect-fields'><span className='header-fields'>Company Created Date: </span>{account.startYear ? account.startYear : <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningAcc', account.id)} />}</div>
                                <div className='prospect-fields'>
                                    <span className='header-fields'>Account Deck: </span>
                                    {account.hubId && account.businessContextFrameworkUrl && account.businessContextFrameworkUrl.includes('.pdf') ?
                                        <Card.Link className='text-primary' href={encodeURI(`${clientSideSettings.hubUrl}${account.hubId}`)} target='_blank' rel='noopener noreferrer'>Business Context Framework</Card.Link>
                                        :
                                        <NotAvailable notavailable='deck' />
                                    }
                                </div>
                            </Col>
                            <Col md lg={5}>
                                {checkAccountType(account.accountType) ?
                                    <div>
                                        <div className='prospect-fields'>
                                            <span className='header-fields'>Account PMO: </span>
                                            <span> <EmployeeDetails data={account.accountPMO} isPeopleSearchLive={clientSideSettings.isPeopleSearchLive} peopleSearchUrl={encodeURI(clientSideSettings.peopleSearchUrl)} isPMO={true}/> </span>
                                        </div>
                                        <div className='prospect-fields'>
                                            <span className='header-fields'>Account Leader: <OverlayTooltip tooltipText={"The MD assigned as the global lead of a Strategic or Key account."} hoverElement={<img src={questionMark} className='notavailable-icon' alt='Question' />} imageType={'question'} /> </span>
                                            <span> <EmployeeDetails data={account.accountExecutive} isPeopleSearchLive={clientSideSettings.isPeopleSearchLive} peopleSearchUrl={encodeURI(clientSideSettings.peopleSearchUrl)} /> </span>
                                        </div>
                                    </div>
                                    :
                                    <div className='prospect-fields'>
                                        <span className='header-fields'>Account MD/D <OverlayTooltip tooltipText={"The MD assigned to this account."} hoverElement={<img src={questionMark} className='notavailable-icon' alt='Question' />} imageType={'question'} />: </span>
                                        <span> <EmployeeDetails data={account.accountMDD} isPeopleSearchLive={clientSideSettings.isPeopleSearchLive} peopleSearchUrl={encodeURI(clientSideSettings.peopleSearchUrl)} /> </span>
                                    </div>
                                }
                            </Col>                   
                        </Row>
                    </div>
                    <hr />
                    <div style={{ marginBottom: '15px' }}>
                        <div>
                            {account.relProAccountId ?
                                <div className='connections-subtitle' style={{ marginBottom: '8px', display: 'inline-block'}}>
                                    <Link className='text-primary' to={`/orgchart/${account.id}/${encodeURIComponent(account.relProAccountId)}`}><SupervisedUserCircleOutlinedIcon /> Organizational Chart</Link>
                                </div>
                                :
                                null
                            }
                            <div className='connections-subtitle' style={{ marginBottom: '8px', display: 'inline-block', float: 'right' }}>
                                {account.hubId ? <Button className='hubBtn' style={{ float: 'right', marginBottom: '15px', fontSize: '90%', marginLeft: '10px' }} href={encodeURI(`${clientSideSettings.hubUrl}${account.hubId}`)} target='_blank' rel='noopener noreferrer'>THE HUB</Button> : null}
                                <Button className='hubBtn' style={{ float: 'right', marginBottom: '15px', fontSize: '90%', marginLeft: '10px', width: '94px' }} href={encodeURI(`${clientSideSettings.sfdcUrl}${account.id}`)} target='_blank' rel='noopener noreferrer'><img style={{ height: '16px' }} src={salesforce} /></Button>
                                {urlMap(account.socialMediaUrls)}
                            </div>
                            <div className='prospect-fields'>
                                <span className='header-fields'>Website: </span>
                                {account.websiteUrl ?
                                    <Card.Link className='text-primary' href={formatWebsite(account.websiteUrl)} target='_blank' rel='noopener noreferrer'>{account.websiteUrl}</Card.Link>
                                    :
                                    <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningAcc', account.id)} />
                                }
                            </div>
                            <div className='prospect-fields'>
                                <span className='header-fields'>Child Company: </span>
                                {account.accountChild && account.accountChild.length > 0 ?
                                    <span>
                                        {(showMoreChild ? account.accountChild : account.accountChild.slice(0, 5)).map((child, index) => (
                                            <span key={index} style={{ marginRight: '15px' }}>
                                                {renderChildParent(child.id, child.name)}
                                            </span>
                                        ))}
                                        {account.accountChild.length > 5 ? <span className='child-show' onClick={() => setShowMoreChild(!showMoreChild)}>{showMoreChild ? 'View Less' : `View More (${account.accountChild.length})`}</span> : null}
                                    </span>
                                    :
                                    "Not Available/Not Applicable"
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};