const hostname = window && window.location && window.location.hostname;
var mailBody = 'This issue occurred here: (url). Please describe your issue below: ' //this includes url so that it can be replaced with the url of current page in the header component

var backendHost = `https://${hostname}`;
if (hostname === 'localhost') {
    backendHost = 'https://localhost:44356';
}

const config = {
    azRedirectUri: backendHost,
    supportEmail: 'ProConnectSupport@Protiviti.com',
    supportMailSubject: 'ProConnect Issue- ',
    supportMailBody: mailBody
}

module.exports = config;