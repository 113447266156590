import React, { useEffect, useState } from 'react';
import './Prospect/Overview.css';
import { OverlayTooltip } from './OverlayTooltip';
import Card from 'react-bootstrap/Card';
import infoIcon from '../images/i-icon.png'

export const NotAvailable = (props) => {
    const [tooltipText, setTooltipText] = useState('');

    useEffect(() => {
        let text = '';
        if (props.notavailable === 'companynews') {
            text = 'No news articles were found for this company at this time.';
        }
        else if (props.notavailable === 'external') {
            text = 'This information is not available in the vendor database.';
        }
        else if (props.notavailable === 'title') {
            text = 'There is no data currently stored within our internal Protiviti data for this section. If you know this information, please update within the system (e.g., SalesForce, iManage, etc.)';
        }
        else if (props.notavailable === 'solution') {
            text = 'There are no projects with incurred fees for this client in the given time period selected.'
        }
        else if (props.notavailable === 'field') {
            text = 'This information is not available within our internal Protiviti data. If you know the information, please update in the internal system. Note: ProConnect follows the same security protocols as our other Protiviti internal applications. Therefore, your access to update may be restricted based on your role and region.'
        }
        else if (props.notavailable === 'no-update') {
            text = 'This information is not available within our internal Protiviti data.'
        }
        else if (props.notavailable === 'marketingCampaign')
        {
            text = 'This information is not available within our internal Protiviti data.'
        }
        else if (props.notavailable === 'deck') {
            text = 'There is no account deck stored in our internal systems for this account.'
        }
        else if (props.notavailable === 'ownership')
            text = 'If this information is not accurate, please update in the internal system. Note: ProConnect follows the same security protocols as our other Protiviti internal applications. Therefore, your access to update may be restricted based on your role and region.'
        else {
            text = 'This data point is not currently stored within our internal Protiviti data. If you know this information, please update within the system (e.g., SalesForce, iManage, etc.)';
        }
        setTooltipText(text);
    }, [props]);
    return (
        <span style={props.style} data-testid="not-available">
            {((props.notavailable !== "title" && props.notavailable !== "companynews" && props.notavailable !== "solution" && props.notavailable !== "ownership" && props.notavailable !== 'marketingCampaign' && props.notavailable !== 'external') || props.notavailable === "noResult") ?
                <span>Not Available </span>
                : null
            }
            {(props.notavailable !== "section" && props.linkUrl === '') ?
                <OverlayTooltip tooltipText={tooltipText} hoverElement={<img src={infoIcon} className='notavailable-icon' />} />
                : null
            }
            {(props.linkUrl !== '' && props.notavailable !== "noResult") ?
                <Card.Link className='text-primary' href={props.linkUrl} target='_blank' rel="noopener noreferrer"><OverlayTooltip tooltipText={tooltipText} hoverElement={< img src={infoIcon} className='notavailable-icon' />} /></Card.Link>
                : null
            }
            
        </span>
    );
};