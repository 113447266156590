import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import SpeakerNotesOutlinedIcon from '@material-ui/icons/SpeakerNotesOutlined';
import { NotAvailable } from '../NotAvailable';
import { getUrlLink, renderSortArrow, formatDate, getFormatCompanyLink } from './Util';
import infoIcon from '../../images/i-icon.png';
import { OverlayTooltip } from '../OverlayTooltip';

export const ScoopsIntents = () => {
    const { account, scoops, intents } = useContext(GlobalContext);
    //const [loading, setLoading] = useState(true);
    const [showMore, setShowMore] = useState(false);
    //const [showFilters, setShowFilters] = useState(true);
    //const [showFiltersModal, setShowFiltersModal] = useState(true);
    //const [currentFilters, setCurrentFilters] = useState({});
    //const [staticOption, setStaticOption] = useState({});
    //const [filteredData, setFilteredData] = useState([]);
    const [componentState, setComponentState] = useState('scoops');

    //let publishedDateFilter, origPublishedDateFilter, linkTextFilter, descriptionFilter, topicsFitler, typesFilter, contactsFilter;
    //let signalDateFilter, categoryFilter, intentTopicFilter, signalScoreFilter, audienceFilter;

    //const clearScoopFilters = () => {
    //    publishedDateFilter([]);
    //    origPublishedDateFilter([]);
    //    linkTextFilter([]);
    //    descriptionFilter([]);
    //    topicsFitler([]);
    //    typesFilter([]);
    //    contactsFilter([]);

    //    setCurrentFilters({});
    //    setStaticOption({});
    //}

    //const clearIntentFilters = () => {

    //    signalDateFilter([]);
    //    categoryFilter([]);
    //    intentTopicFilter([]);
    //    signalScoreFilter([]);
    //    audienceFilter([]);
        
    //    setCurrentFilters({});
    //    setStaticOption({});

    //}

    //const onStaticOptionsUpdate = (staticColumn, filterKey, noFilters) => {
    //    if (noFilters || !staticOption.hasOwnProperty(filterKey))
    //        setStaticOption(getOnStaticOptionsUpdate(staticColumn, filterKey, noFilters, staticOption));
    //}

    //const afterFilter = (newResult, newFilters) => {
    //    setCurrentFilters(prevData => {
    //        if (JSON.stringify(prevData) !== JSON.stringify(newFilters)) {
    //            return newFilters
    //        }
    //        return prevData
    //    });

    //    setFilteredData(prevData => {
    //        if (JSON.stringify(prevData) !== JSON.stringify(newResult)) {
    //            return newResult
    //        }
    //        return prevData
    //    });
    //}

    const ScoopsTableColumns = [
        {
            dataField: 'publishedDate',
            text: 'Published Date',
            formatter: (value, row) => (
                (value && value.substring(0, 4) !== '0001') ? formatDate(value) : 'Not Available'
            ),
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '100px' },
            //filter: customFilter({
            //    type: FILTER_TYPES.MULTISELECT,
            //    getFilter: (filter) => {
            //        publishedDateFilter = filter;
            //    },
            //    onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'publishedDate')
            //}),
            //filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={scoops} filterKey={'publishedDate'} showFilters={showMore ? showFiltersModal : showFilters} format={'date'} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        //{
        //    dataField: 'originalPublishedDate',
        //    text: 'Orig Published Date',
        //    formatter: (value, row) => (
        //        (value && value.substring(0, 4) !== '0001') ? formatDate(value) : <NotAvailable notavailable='field' />
        //    ),
        //    sort: true,
        //    sortCaret: renderSortArrow,
        //    headerStyle: { fontWeight: '500', width: '100px' },
        //    filter: customFilter({
        //        type: FILTER_TYPES.MULTISELECT,
        //        getFilter: (filter) => {
        //            origPublishedDateFilter = filter;
        //        },
        //        onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'originalPublishedDate')
        //    }),
        //    filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={scoops} filterKey={'originalPublishedDate'} showFilters={showMore ? showFiltersModal : showFilters} format={'date'} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        //},
        //{
        //    dataField: 'linkText',
        //    text: 'Link Text',
        //    sort: false,
        //    sortCaret: renderSortArrow,
        //    formatter: (value, row) => (value ? value : <NotAvailable notavailable='field' />),
        //    headerStyle: { fontWeight: '500', width: '150px' },
        //    filter: customFilter({
        //        type: FILTER_TYPES.MULTISELECT,
        //        getFilter: (filter) => {
        //            linkTextFilter = filter;
        //        },
        //        onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'linkText')
        //    }),
        //    filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={scoops} filterKey={'linkText'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        //},
        {
            dataField: 'description',
            text: 'Description',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ? value : 'Not Available'),
            headerStyle: { fontWeight: '500', width: '500px' },
            //filter: customFilter({
            //    type: FILTER_TYPES.MULTISELECT,
            //    getFilter: (filter) => {
            //        descriptionFilter = filter;
            //    },
            //    onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'description')
            //}),
            //filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={scoops} filterKey={'description'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'topic',
            text: 'Topic',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ? value : 'Not Available'),
            headerStyle: { fontWeight: '500', width: '100px' },
            //filter: customFilter({
            //    type: FILTER_TYPES.MULTISELECT,
            //    getFilter: (filter) => {
            //        topicsFitler = filter;
            //    },
            //    onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'topic')
            //}),
            //filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={scoops} filterKey={'topic'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'type',
            text: 'Types',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ? value : 'Not Available'),
            headerStyle: { fontWeight: '500', width: '100px' },
            //filter: customFilter({
            //    type: FILTER_TYPES.MULTISELECT,
            //    getFilter: (filter) => {
            //        typesFilter = filter;
            //    },
            //    onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'type')
            //}),
            //filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={scoops} filterKey={'type'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'contact',
            text: 'Contacts',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ? value : 'Not Available'),
            headerStyle: { fontWeight: '500', width: '100px' },
            //filter: customFilter({
            //    type: FILTER_TYPES.MULTISELECT,
            //    getFilter: (filter) => {
            //        contactsFilter = filter;
            //    },
            //    onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'contact')
            //}),
            //filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={scoops} filterKey={'contact'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'id',
            hidden: true
        }
    ];

    const IntentTableColumns = [
        {
            dataField: 'signalDate',
            text: 'Signal Dates',
            formatter: (value, row) => (
                (value && value.substring(0, 4) !== '0001') ? formatDate(value) : 'Not Available'
            ),
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '100px' },
            //filter: customFilter({
            //    type: FILTER_TYPES.MULTISELECT,
            //    getFilter: (filter) => {
            //        signalDateFilter = filter;
            //    },
            //    onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'signalDate')
            //}),
            //filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={intents} filterKey={'signalDate'} showFilters={showMore ? showFiltersModal : showFilters} format={'date'} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'category',
            text: 'Category',
            formatter: (value, row) => (value ? value : 'Not Available'),
            sort: false,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '100px' },
            //filter: customFilter({
            //    type: FILTER_TYPES.MULTISELECT,
            //    getFilter: (filter) => {
            //        categoryFilter = filter;
            //    },
            //    onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'category')
            //}),
            //filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={intents} filterKey={'category'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'topic',
            text: 'Topic',
            sort: false,
            sortCaret: renderSortArrow, 
            formatter: (value, row) => (value ? value : 'Not Available'),
            headerStyle: { fontWeight: '500', width: '100px' },
            //filter: customFilter({
            //    type: FILTER_TYPES.MULTISELECT,
            //    getFilter: (filter) => {
            //        intentTopicFilter = filter;
            //    },
            //    onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'topic')
            //}),
            //filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={intents} filterKey={'topic'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'signalScore',
            text: 'Score',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ? value : 'Not Available'),
            headerStyle: { fontWeight: '500', width: '100px' },
            hidden: true
            //filter: customFilter({
            //    type: FILTER_TYPES.MULTISELECT,
            //    getFilter: (filter) => {
            //        signalScoreFilter = filter;
            //    },
            //    onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'signalScore')
            //}),
            //filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={intents} format={'number'} filterKey={'signalScore'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'audienceStrength',
            text: 'Audience Strength',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ? audienceStrengthMap(value) : 'Not Available'),
            headerStyle: { fontWeight: '500', width: '100px' },
            headerFormatter: column => <span>{column.text} {renderHelpText('intents-audience')}</span>
            //filter: customFilter({
            //    type: FILTER_TYPES.MULTISELECT,
            //    getFilter: (filter) => {
            //        audienceFilter = filter;
            //    },
            //    onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'audienceStrength')
            //}),
            //filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={intents}  filterKey={'audienceStrength'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'recommendedContacts',
            text: 'Recommended Contacts',
            sort: false,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (
                <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                    {value.map(element => <li key={element.id}>{element.firstName + ' ' + element.lastName}</li>)}
                </ul>
            ),
            headerStyle: { fontWeight: '500', width: '150px' },
            //filter: customFilter({
            //    type: FILTER_TYPES.MULTISELECT,
            //    getFilter: (filter) => {
            //        intentscontactsFilter = filter;
            //    },
            //    onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'recommendedContacts')
            //}),
            //filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={intents} filterKey={'recommendedContacts'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'intentId',
            hidden: true
        }
       
    ];

    const renderHelpText = (from) => {
        let tooltipText = '';

        if (from === 'scoops') tooltipText = "Proprietary surveys and phone interviews that are conducted by ZoomInfo's research team, as well as news from the general web.";
        if (from === 'intents') tooltipText = "Topics that companies are actively researching, beyond two standards of deviation of standard research activity by the company.";
        if (from === 'intents-audience') tooltipText = "How large the audience was that conducted the research, with a larger group represented by a 5.";

        return (
            <OverlayTooltip tooltipText={tooltipText} hoverElement={<img src={infoIcon} className='notavailable-icon' />} />
        );
    }

    const audienceStrengthMap = (letter) => {
        // A=5, B=4, C=3
        if (letter === 'A') return '5';
        else if (letter === 'B') return '4';
        else if (letter === 'C') return '3';
        else return 'Not Available';
    }

    const handleComponentStateChange = (from) => {
        if (componentState !== from) {
            if (componentState === 'scoops') {
                //clearScoopFilters();
                setComponentState('intents');
            }
            else {
                //clearIntentFilters();
                setComponentState('scoops');
            }
        }
    }

    const handleShowMore = () => {
        setShowMore(!showMore);
        //clearAllFilters();
    }

    const showMoreModal = () => {
        return (
            <Modal show={showMore} onHide={handleShowMore} dialogClassName='custom-dialog'>
                <Modal.Header closeButton className='card-style' style={{ borderTopColor: '#00A28F' }}>
                    <Modal.Title>
                        {componentState === 'scoops' ?
                            scoops && scoops.length > 0 ?
                                <h4 className='card-title-modal'>
                                    <span><SpeakerNotesOutlinedIcon fontSize="large" /> Scoops </span>
                                    {(scoops && scoops.length > 0) ? <span style={{ marginLeft: '10px' }}>{renderHelpText('scoops')}</span> : null}
                                </h4>
                                :
                                null
                            :
                            intents && intents.length > 0 ?
                                <h4 className='card-title-modal'>
                                    <span><SpeakerNotesOutlinedIcon fontSize="large" /> Intents </span>
                                    {(intents && intents.length > 0) ? <span style={{ marginLeft: '10px' }}>{renderHelpText('intents')}</span> : null}
                                </h4>
                                :
                                null
                        }
                    </Modal.Title>
                    <div className='trigger-filter-modal'>
                        <div className='sfdc-contact-owner'>
                            {getFormatCompanyLink(account.id, account.name)}
                        </div>
                    </div>

                    {/*<div className='trigger-filter-modal'>*/}
                    {/*    <div className='filter-button'>*/}
                    {/*        <div>*/}
                    {/*            {triggerFilter(showFiltersModal, setShowFiltersModal)}*/}
                    {/*        </div>*/}
                    {/*        <div className='clear-filter'>*/}
                    {/*            <div className='text-primary' onClick={componentState === 'scoops' ? clearScoopFilters : clearIntentFilters} style={{ visibility: showFiltersModal ? 'visible' : 'hidden' }}>*/}
                    {/*                Clear Filters*/}
                    {/*        </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </Modal.Header>
                <Modal.Body style={{ overflowX: 'auto', paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px', paddingBottom: '80px' }}>
                    {
                        <BootstrapTable
                            keyField='id'
                            data={componentState === 'scoops' ? scoops : intents}
                            columns={componentState === 'scoops' ? ScoopsTableColumns : IntentTableColumns}
                            striped={true}
                            bordered={false}
                            hover={true}
                            //filter={filterFactory({ afterFilter })}
                        />
                    }
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <Card>
            <Card.Body className='card-style' style={{ borderTopColor: "#00A28F", overflowY: 'hidden', overflowX: ((scoops && scoops.length > 0) || (intents && intents.length > 0)) ? 'auto' : 'none' }}>
                <div className='space-between'>
                    <div className='card-title'>
                        <SpeakerNotesOutlinedIcon fontSize="large" style={{ float: 'left' }} />
                        <button className="opportunity-title" onClick={(e) => handleComponentStateChange('scoops', e)}
                            style={{
                                float: 'left',
                                fontWeight: componentState === 'scoops' ? 'bold' : null,
                                borderBottom: componentState === 'scoops' ? '3px solid #F6871F' : 'none',
                                color: '#004068'
                            }}>
                            <span> Scoops </span>
                        </button>
                        <div style={{ height: 60, width: 1, background: '#000', float: "left", marginLeft: 30, marginRight: 30, marginTop: -10 }}></div>
                        <button className="opportunity-title" onClick={(e) => handleComponentStateChange('intents', e)}
                            style={{
                                float: 'center',
                                fontWeight: componentState === 'intents' ? 'bold' : null,
                                borderBottom: componentState === 'intents' ? '3px solid #F6871F' : 'none',
                                marginLeft: '0',
                                color: '#004068'
                            }}>
                            <span> Intents </span>
                        </button>
                        <span style={{ marginLeft: '20px' }}>
                            {componentState === 'scoops' && scoops && scoops.length > 0 ? renderHelpText('scoops') : null}
                            {componentState === 'intents' && intents && intents.length > 0 ? renderHelpText('intents') : null}
                        </span>
                    </div>

                    <div className='sfdc-contact-owner'>
                        {getFormatCompanyLink(account.id, account.name)}
                    </div>

                    {/*<div style={{ display: 'flex' }}>*/}
                    {/*    <div className='filter-button'>*/}
                    {/*        <div>*/}
                    {/*            {componentState === 'scoops' ?*/}
                    {/*                ((scoops && scoops.length > 1) ? triggerFilter(showFilters, setShowFilters) : null)*/}
                    {/*                :*/}
                    {/*                ((intents && intents.length > 1) ? triggerFilter(showFilters, setShowFilters) : null)*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*        <div className='clear-filter'>*/}
                    {/*            <div className='text-primary' onClick={componentState === 'scoops' ? clearScoopFilters : clearIntentFilters} style={{ visibility: (((componentState === 'scoops' && scoops && scoops.length > 0) || (componentState === 'intents' && intents && intents.length > 0)) && showFilters) ? 'visible' : 'hidden' }}>*/}
                    {/*                Clear Filters*/}
                    {/*                </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {componentState === 'scoops' ?
                    (scoops && scoops.length > 0) ?
                        <div className='result-table'>
                            <BootstrapTable
                                keyField='id'
                                data={scoops}
                                columns={ScoopsTableColumns}
                                striped={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({
                                    page: 1,
                                    sizePerPage: 5,
                                    hideSizePerPage: true,
                                    hidePageListOnlyOnePage: true
                                })}
                                //filter={filterFactory({ afterFilter })}
                            />
                            <Button
                                style={{ float: 'right', margin: "2%", display: (scoops && scoops.length <= 5) ? 'none' : null }}
                                onClick={handleShowMore}
                                variant="outline-secondary">
                                {'View More'}
                            </Button>
                            {showMoreModal()}
                        </div>
                        :
                        <>
                            <hr />
                            <div className='not-available'><NotAvailable notavailable='noResult' /></div>
                        </>
                    :
                    (intents && intents.length > 0) ?
                        <div className='result-table'>
                            <BootstrapTable
                                keyField='id'
                                data={intents}
                                columns={IntentTableColumns}
                                striped={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({
                                    page: 1,
                                    sizePerPage: 5,
                                    hideSizePerPage: true,
                                    hidePageListOnlyOnePage: true
                                })}
                                //filter={filterFactory({ afterFilter })}
                            />
                            <Button
                                style={{ float: 'right', margin: "2%", display: (intents && intents.length <= 5) ? 'none' : null }}
                                onClick={handleShowMore}
                                variant="outline-secondary">
                                {'View More'}
                            </Button>
                            {showMoreModal()}
                        </div>
                        :
                        <>
                            <hr />
                            <div className='not-available'><NotAvailable notavailable='noResult' /></div>
                        </>
                }
            </Card.Body>
        </Card>
    );
}