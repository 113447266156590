import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import config from '../../config';
import { NotAvailable } from '../NotAvailable';
import { OverlayTooltip } from '../OverlayTooltip';
import { getUrlLink, renderSortArrow, formatText, triggerFilter, handleOnFilterTable, formatEndYear, asteriskHoverover, getFormatCompanyLink, getOnStaticOptionsUpdate } from '../Shared/Util';
import { FilterMultiSelect } from '../Shared/FilterMultiSelect';
import filterFactory, { customFilter, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import { PowerBI } from '../Shared/PowerBI';

export const ProtivitiWork = () => {
    const { account, prospect } = useContext(GlobalContext);
    const [showMore, setShowMore] = useState(false);
    const [protivitiWork, setProtivitiWork] = useState([]);
    const [showFilters, setShowFilters] = useState(true);
    const [showFiltersModal, setShowFiltersModal] = useState(true);
    const [powerBILink, setPowerBILink] = useState(null);
    const [clientSideSettings] = useState(JSON.parse(localStorage.getItem('ClientSideSettings')));
    const [currentFilters, setCurrentFilters] = useState({});
    const [staticOption, setStaticOption] = useState({});
    const [filteredData, setFilteredData] = useState([]);

    let nameFilter, yearEndedFilter, solutionFilter, emdFilter, emFilter;

    useEffect(() => {
        if (account.project.length < 2) {
            setShowFilters(!showFilters)
        }
    }, [account]);

    useEffect(() => {
        if (account && account.project) {
            setProtivitiWork(account.project);
            setFilteredData(account.project);
        }
        if (account && account.clientCodes && account.clientCodes.length > 0) {
            let powerBILink = clientSideSettings.powerBIUrl;
            let clientCodes = account.clientCodes.map((clientCode, i) => {
                return `'${clientCode}'${i !== account.clientCodes.length-1 ? ',' : ''}` 
            }).join(''); 
            powerBILink += encodeURIComponent(`${clientCodes})`).replace(/\)/g, "%29"); 

            setPowerBILink(powerBILink);
        }
    }, [account]);

    //const renderKeyBuyerLink = (value, row) => {
    //    if (row.pkbIsNotProspect || (prospect && row.primaryKeyBuyerId === prospect.id)) return row.pkbIsNotProspect ? asteriskHoverover(value, true) : value;
    //    else return <a href={`${config.azRedirectUri}/prospect/${row.primaryKeyBuyerId}`}>{value}</a>
    //}

    const onStaticOptionsUpdate = (staticColumn, filterKey, noFilters) => {
        if (noFilters || !staticOption.hasOwnProperty(filterKey))
            setStaticOption(getOnStaticOptionsUpdate(staticColumn, filterKey, noFilters, staticOption));
    }

    const afterFilter = (newResult, newFilters) => {
        setCurrentFilters(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(newFilters)) {
                return newFilters
            }
            return prevData
        });

        setFilteredData(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(newResult)) {
                return newResult
            }
            return prevData
        });
    }

    const ProjectsTableColumns = [
        {
            dataField: 'name',
            text: 'Project',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ?
                formatText(value, 30).length > 30 ?
                    <OverlayTooltip tooltipText={value} hoverElement={
                        <span>{formatText(value, 30)}</span>
                    } />
                    :
                    formatText(value, 30)
                : <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningAcc', row.partitionKey)} />),
            headerStyle: { fontWeight: '500', width: '200px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    nameFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'name')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={protivitiWork} filterKey={'name'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'endedDate',
            text: 'Year Ended',
            formatter: (value, row, rowIndex) => formatEndYear(row) || <NotAvailable notavailable='field' />,
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '120px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    yearEndedFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'endedDate')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={protivitiWork} filterKey={'endedDate'} showFilters={showMore ? showFiltersModal : showFilters} format={'date-year'} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'solution',
            text: 'Solution',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ? value : <NotAvailable notavailable='field' />),
            headerStyle: { fontWeight: '500', width: '120px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    solutionFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'solution')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={protivitiWork} filterKey={'solution'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'engagementManagingDirector',
            text: 'EMD',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ? (row.engagementManagingDirectorIsDeleted ? asteriskHoverover(value, false) : value) : <NotAvailable notavailable='field' />),
            headerStyle: { fontWeight: '500', width: '120px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    emdFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'engagementManagingDirector')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={protivitiWork} filterKey={'engagementManagingDirector'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'engagementManager',
            text: 'EM',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ? (row.engagementManagerIsDeleted ? asteriskHoverover(value, false) : value) : <NotAvailable notavailable='field' />),
            headerStyle: { fontWeight: '500', width: '120px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    emFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'engagementManager')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={protivitiWork} filterKey={'engagementManager'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'id',
            hidden: true
        }
    ];

    const clearAllFilters = () => {
        nameFilter([]);
        yearEndedFilter([]);
        solutionFilter([]);
        emdFilter([]);
        emFilter([]);
        setCurrentFilters({});
        setStaticOption({});
    }

    const handleShowMore = () => {
        setShowMore(!showMore);
        clearAllFilters();
    }

    const showMoreModal = () => {
        return (
            <Modal show={showMore} onHide={handleShowMore} dialogClassName='custom-dialog'>
                <Modal.Header closeButton className='card-style' style={{ borderTopColor: '#00A28F' }}>
                    <Modal.Title>
                        {account && account.name ?
                            <h4 className='card-title-modal'>
                                <span><WorkOutlineIcon fontSize="large" /> Protiviti Projects </span>
                                {account.project.length > 0 ? null : <NotAvailable notavailable='title' linkUrl={getUrlLink('imanageFilter', account.id)} />}
                                <div className='project-subtitle'>These represent any projects that have been tagged to this account (not dependent on incurred fees)</div>
                            </h4>
                            :
                            null
                        }
                    </Modal.Title>
                    <div className='trigger-filter-modal'>
                        <div className='filter-button'>
                            <div>
                                {triggerFilter(showFiltersModal, setShowFiltersModal)}
                            </div>
                            <div className='clear-filter'>
                                <div className='text-primary' onClick={clearAllFilters} style={{ visibility: showFiltersModal ? 'visible' : 'hidden' }}>
                                    Clear Filters
                                </div>
                            </div>
                        </div>
                        <div className='sfdc-contact-owner'>
                            {getFormatCompanyLink(account.id, account.name)}
                            <div className='employee-name'>Total # of Projects: {account.numberOfProject}</div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ overflowX: 'auto', paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px', paddingBottom: '80px' }}>
                    {
                        <BootstrapTable
                            keyField='id'
                            data={protivitiWork}
                            columns={ProjectsTableColumns}
                            striped={true}
                            bordered={false}
                            hover={true}
                            filter={filterFactory({ afterFilter })}
                        />
                    }
                </Modal.Body>
            </Modal>
        )
    }
    
    return (
        <Card>
            <Card.Body className='card-style' style={{ borderTopColor: "#00A28F", overflowY: 'hidden', overflowX: (account.project.length > 0) ? 'auto' : 'none' }}>
                <div className='space-between'>
                    {account && account.name ?
                        <h4 className='card-title'>
                            <span><WorkOutlineIcon fontSize="large" /> Protiviti Projects </span>
                            {account.project.length > 0 ? null : <NotAvailable notavailable='title' linkUrl={getUrlLink('imanageFilter', account.id)} />}
                            <div className='project-subtitle'>These represent any projects that have been tagged to this account (not dependent on incurred fees)</div>
                        </h4>
                        :
                        null
                    }
                    <div style={{ display: 'flex' }}>
                        {powerBILink ?
                            <div className='protiviti-work-field' style={{ textAlign: 'center', paddingRight: '10px' }}>
                                <PowerBI url={ powerBILink } toolTipText={"Your access to this dashboard may be limited given your role and region."} />
                                <div className='bi-subtitle'>Account Financial Data</div>
                            </div>
                            : null
                        }
                        {account.project.length > 1 ?
                            <div className='filter-button'>
                                <div>
                                    {triggerFilter(showFilters, setShowFilters)}
                                </div>
                                <div className='clear-filter'>
                                    <div className='text-primary' onClick={clearAllFilters} style={{ visibility: showFilters ? 'visible' : 'hidden' }}>
                                        Clear Filters
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className='sfdc-contact-owner'>
                            {getFormatCompanyLink(account.id, account.name)}
                            <div className='employee-name'>Total # of Projects: {account.numberOfProject}</div>
                        </div>
                    </div>
                </div>
                {
                    account.project.length > 0 ?
                        <div className='result-table'>
                            <BootstrapTable
                                keyField='id'
                                data={protivitiWork}
                                columns={ProjectsTableColumns}
                                striped={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({
                                    page: 1,
                                    sizePerPage: 5,
                                    hideSizePerPage: true,
                                    hidePageListOnlyOnePage: true
                                })}
                                filter={filterFactory({ afterFilter })}
                            />
                            <Button
                                style={{ float: 'right', margin: "2%", display: protivitiWork.length <= 5 ? 'none' : null }}
                                onClick={handleShowMore}
                                variant="outline-secondary">
                                {'View More'}
                            </Button>
                            {showMoreModal()}
                        </div>
                        :
                        <>
                            <hr />
                            <div className='not-available'><NotAvailable notavailable='noResult' /></div>
                        </>
                }
            </Card.Body>
        </Card>
    );
};