import React, { useContext, useState, useRef } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import './Overview.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { NotAvailable } from '../NotAvailable';
import { EmployeeLinks } from '../Shared/EmployeeLinks';
import questionMark from '../../images/questionmark-icon.png'
import { OverlayTooltip } from '../OverlayTooltip';
import { getUrlLink, asteriskHoverover } from '../Shared/Util';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { Link } from 'react-router-dom';
import { ShowInternalConnections } from '../Shared/ShowInternalConnection'; 


export const InternalConnections = () => {

    const { prospect } = useContext(GlobalContext);
    const [showMore, setShowMore] = useState(false);
    const [clientSideSettings] = useState(JSON.parse(localStorage.getItem('ClientSideSettings')));

    const handleShowMore = () => setShowMore(!showMore);

    const showMoreModal = () => {
        return (
            <Modal show={showMore} onHide={handleShowMore} dialogClassName='custom-dialog'>
                <Modal.Header closeButton className='card-style' style={{ borderTopColor: '#5D7B9A', borderBottom: '0px' }}>
                    <Modal.Title>
                        <h4 className='card-title-modal'><PeopleOutlineIcon fontSize="large" /> Internal Connections</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflow: 'auto', paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px' }}>
                    <div className='internal-connections-list'>
                        {ShowInternalConnections(prospect.connections, 'prospect', prospect.id)}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <div>
            <Card>
                <Card.Body className='card-style' style={{ borderTopColor: "#5D7B9A" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4 className='card-title' style={{ alignSelf: 'center' }}>
                            <PeopleOutlineIcon fontSize="large" /> Internal Connections {(prospect.connections && prospect.connections.length > 0) ? null : <NotAvailable notavailable='title' linkUrl={getUrlLink('sfdclightningCon', prospect.id)} />}
                            <div className='project-subtitle' style={{ width: '670px', paddingBottom: '10px' }}>These are colleagues who have connected with this contact most recently based on logged Salesforce events. Note, Tagged relationships (A-List, QBR, RBC, TR, NR) are listed first regardless of the date of interaction.</div>
                            {(prospect.connections && prospect.connections.length > 0) ?
                                <div className='connections-subtitle'>
                                    <Link className='text-primary' to={`/connections/${prospect.id}`}><UnfoldMoreIcon style={{ transform: 'rotate(45deg)' }} /> Connection Map View</Link>
                                </div>
                                :
                                null
                            }
                        </h4>
                        {
                            <div className='sfdc-contact-owner'>
                                <div className='fields-style'>Relationship Lead <OverlayTooltip tooltipText={"Identifies the person primarily responsible for Protiviti's relationship with this contact."} hoverElement={<img src={questionMark} className='notavailable-icon' />} imageType={'question'} /></div>
                                {
                                    prospect.relationshipLead ?
                                        <div>
                                            <div className='employee-name'>{prospect.relationshipLead.isTerminated ? asteriskHoverover(prospect.relationshipLead.name, false) : prospect.relationshipLead.name}{prospect.relationshipLead.company ? ', ' + prospect.relationshipLead.company : null}</div>
                                            <div className='employee-info'>
                                                {
                                                    ((prospect.relationshipLead.title && prospect.relationshipLead.title.indexOf(' (') > 0) ? prospect.relationshipLead.title.substring(0, prospect.relationshipLead.title.indexOf(' (')) : prospect.relationshipLead.title) +
                                                    ((prospect.relationshipLead.title && prospect.relationshipLead.city) ? ', ' : '') +
                                                    (prospect.relationshipLead.city ? prospect.relationshipLead.city + ' Office' : '')
                                                }
                                            </div>
                                            {!prospect.relationshipLead.isTerminated ? <EmployeeLinks data={prospect.relationshipLead} isPeopleSearchLive={clientSideSettings.isPeopleSearchLive} peopleSearchUrl={encodeURI(clientSideSettings.peopleSearchUrl)} /> : null}
                                        </div>

                                        :
                                        <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningCon', prospect.id)} />
                                }
                            </div>
                        }
                    </div>
                    {(prospect.connections && prospect.connections.length > 0) ?
                        <div className='internal-connections-list'>
                            {ShowInternalConnections(prospect.connections.slice(0, 3), 'prospect', prospect.id)}
                            <Button
                                style={{ float: 'right', margin: "2%", display: prospect.connections.length <= 3 ? 'none' : null }}
                                variant="outline-secondary"
                                onClick={handleShowMore}>
                                {'View More'}
                            </Button>
                            {showMoreModal()}
                        </div>
                        :
                        <>
                            <hr />
                            <div className='not-available'><NotAvailable notavailable='noResult' /></div>
                        </>
                    }
                </Card.Body>
            </Card>
        </div>
    );
};