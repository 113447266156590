import React, { useContext } from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import { CardDeck, Card, Button } from 'react-bootstrap/';
import { GlobalContext } from '../../context/GlobalState';
import { formatAccountName, provideLinkToCompanyPage, formatDate, renderTaggedRelationship } from '../Shared/Util';
import DefaultPhoto from '../Shared/DefaultPhoto';

export const ProspectCards = (props) => {
	const { following, followProspect } = useContext(GlobalContext);

	const isFollowing = (id) => {
		const followingId = following.map(_ => _.id);
		return (
			<div className='following-button'>
				<Button className={followingId.includes(id) ? 'following-active' : 'following-inactive'} onClick={(e) => triggerFollowProspect(id, e)}>
					{followingId.includes(id) ? 'Following' : 'Follow'}
				</Button>
			</div>
		);
	}


	const triggerFollowProspect = (id, e) => {
		e.preventDefault();
		followProspect(id);
	}
	
    return (
		<CardDeck>
			{props.cards.map((result, index) => {
				return (
					<div key={index} className='search-result-link-follow' >
						<Card className='search-result-card' style={{ textAlign: 'left' }}>
							<Card.Body className='card-style' style={{ borderTopColor: "#F6871F" }}>
								<div className='prospect-background-follow'>
									<div style={{ position: 'relative' }}>
										{/*{result.photoUrl ?
											<img
												className='search-profile-picture'
												src={result.photoUrl}
												alt='Profile Picture'
											/>
											:
											<DefaultPhoto/>
										}*/}
										{result.photoUrl ?
											<div className='profile-pic' style={{ backgroundImage: `url(${result.photoUrl})` }}></div>
											:
											<DefaultPhoto />
										}
										<div style={{ marginTop: '-20px', textAlign: 'center' }}>
											{isFollowing(result.id)}
											{result.showLastModified ? formatDate(result.modifiedDate) : null}
										</div>
									</div>
									<div className='prospect-detail-follow'>
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<h5>
												<Link className='prospect-name' to={`/prospect/${result.id}`}> {result.name}</Link>
											</h5>
											{result.relationShipStrength ? renderTaggedRelationship(result.relationShipStrength) : null}
										</div>
										<div className='current-title'>
											{result.title}
										</div>
										{
											provideLinkToCompanyPage(result.companyId) ? 
											<Link to={`/company/${result.companyId}`} className='company-name'>{result.companyName ? formatAccountName(result.companyName) : null}</Link> :
											<p className='company-name'>{result.companyName ? formatAccountName(result.companyName) : null}</p>
										}
									</div>
								</div>
							</Card.Body>
						</Card>
					</div>
				)
			})}
		</CardDeck>
    );
}