import React, { useState, useEffect, useContext } from 'react';
import './Header.css';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Search, Subject } from '@material-ui/icons';
import { withRouter, useLocation } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import config from '../config';
import { authHeaders } from '../auth/authHeaders';
import MenuIcon from '@material-ui/icons/Menu';
import HelpPage from '../assets/ProConnect_Job_Aid.pdf';
import ProConnectLogo from '../images/proconnect-logo.png';
import { GlobalContext } from '../context/GlobalState';
import { mailSubject } from './Shared/Util';


export const Header = withRouter((props) => {
    const [search, setSearch] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [focusSearch, setFocusSearch] = useState(false);
    const [cursor, setCursor] = useState(null);
    const { following, getFollowing, followAccounts, getFollowingAccounts, authorized, userEmail } = useContext(GlobalContext);
    const [user, setUser] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location && location.state && location.state.authData && location.state.authData.accountInfoUserName) {
            setUser(location.state.authData.accountInfoUserName);
        }
    }, [location]);

    useEffect(() => {
        if (!following || following.length === 0) {
            getFollowing();
        }
    }, [JSON.stringify(following)])

    useEffect(() => {
        if (!followAccounts || followAccounts.length === 0) {
            getFollowingAccounts();
        }
    }, [JSON.stringify(followAccounts)])

    useEffect(() => {
        const timeOut = setTimeout(() => { // delay recommendation results until user stops typing
            searchRecommendation(search);
        }, 400);
        return () => clearTimeout(timeOut);
    }, [search]);

    
    const searchRecommendation = async (searchValue) => {
        if (searchValue === '') {
            setSuggestions([]);
        }
        else {
            let headers = await authHeaders();
            let url = config.azRedirectUri + '/api/Prospects/suggest?highlights=true&fuzzy=true&term=' + searchValue;
            await axios.get(url, headers)
                .then(function (response) {
                    setSuggestions(response.data);
                })
                .catch(function (error) {
                    setSuggestions([]);
                });
        }
    }

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (search !== '') {
            props.history.push('/search?q=' + search);
            document.getElementById('header-searchbar-id').blur();
            setCursor(null);
        }
    };

    const selectResult = (result) => {
        let suggestion = result.replace("<b>", "").replace("</b>", "");
        setSearch(suggestion);
        props.history.push('/search?q=' + suggestion);
        document.getElementById('header-searchbar-id').blur();
        setCursor(null);
    }

    const onBlur = () => {
        setFocusSearch(false);
        setCursor(null);
    }

    const onFocus = () => {
        setFocusSearch(true);
    }

    const handleKeyDown = (e, searchResults) => {
        //13 = enter, 38 = up, 40 = down
        if (e.keyCode === 40 || e.keyCode === 38 || (e.keyCode === 13 && cursor !== null)) {
            e.preventDefault();
        }

        if (e.keyCode === 13 && cursor !== null) {
            selectResult(searchResults[cursor]);
        }

        if (e.keyCode === 40 && cursor === null) {
            setCursor(0);
        }
        else if (e.keyCode === 38 && cursor > 0) {
            setCursor(prevstate => prevstate - 1);
        }
        else if (e.keyCode === 40 && cursor < searchResults.length - 1) {
            setCursor(prevstate => prevstate + 1);
        }
    }

    return (
        <Navbar collapseOnSelect expand="lg" className='header'>
            <Container fluid>
                <Navbar.Brand className='header-title' href="/">
                    <h1 className='header-title-text'><img className='proconnect-logo' src={ProConnectLogo} alt='' /> Pro<span style={{ color: '#3c3d3e', fontWeight: '350' }}>Connect</span></h1>
                </Navbar.Brand>
                {authorized ?
                    <div className='searchbar-input-button'>
                        <Form inline style={{ justifyContent: 'center' }} onSubmit={handleSearchSubmit}>
                            <div className='searchbar-section'>
                                <FormControl id='header-searchbar-id' className={'mr-sm-2 header-searchbar' + (((suggestions.length > 0) && focusSearch) ? ' header-searchbar-radius' : '')} type="text" placeholder='Search for a person or company. Use quotes ("") or "+" to refine your search results' onChange={e => setSearch(e.target.value)} value={search} onBlur={onBlur} onFocus={onFocus} onKeyDown={(event) => handleKeyDown(event, suggestions.slice(0, 5))} />
                                <div className='searchbar-result' style={{ display: ((suggestions.length > 0) && focusSearch) ? 'block' : 'none' }}>
                                    {suggestions.slice(0, 5).map((result, index) => {
                                        return <Dropdown.Item className={cursor === index ? 'active' : null} key={index} onMouseDown={() => selectResult(result)}><div dangerouslySetInnerHTML={{ __html: result }} /></Dropdown.Item> // string to jsx - dangerouslySetInnerHTML is dangerous to use, but is okay in this situation because result is from backend response
                                    })}
                                </div>
                            </div>
                            <Button className='header-searchbar-button' type="submit"><Search style={{ fontSize: '32px' }} /></Button>
                        </Form>
                    </div>
                    :
                    null
                }
            </Container>
            <div className='header-dropdown'>
                <DropdownButton alignRight title={<MenuIcon style={{ fontSize: '32px' }} />} id="dropdown-menu-align-right">
                    <Dropdown.Item eventKey="1" href={HelpPage} target='_blank' rel='noopener noreferrer'>Help</Dropdown.Item>
                    <Dropdown.Item eventKey="2" href={`mailto:${config.supportEmail + '?cc=' + (userEmail ? userEmail : user) + '&subject=' + config.supportMailSubject + mailSubject() + '&body=' + config.supportMailBody.replace('(url)', encodeURIComponent(window.location.href))}`}>Email Support</Dropdown.Item>
                </DropdownButton>
            </div>
        </Navbar>
    );
});