import React, { useEffect, useState } from 'react';
import './Unauthorized.css';
import { Container } from 'react-bootstrap/';
import protivitiLogo from '../images/protiviti-logo.png';
import Welomebackground from '../images/background.PNG';
import { useLocation} from 'react-router-dom';

// Component used as Unauthorized page and Welcome page w/ no user history

export const Unauthorized = (props) => {
    const [user, setUser] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const location = useLocation();

    useEffect(() => {
        let img = new Image();
        img.src = Welomebackground;

        img.onload = () => {
            setImageLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (location && location.state && location.state.authData && location.state.authData.accountInfoUserName) {
            setUser(location.state.authData.accountInfoUserName);
        }
    }, [location]);

    const mailToSupport = () => {
        // To: ProConnectSupport@Protiviti.com
        // Subject: ProConnect Access Issue - {PrincipalName}

        return `mailto:ProConnectSupport@Protiviti.com?cc= ${user}&subject=ProConnect Access Issue - ${user}`;
    }

    if (!imageLoaded) return null;

    return (
        <div className='unauthorized-page-background' style={{ backgroundImage: `url(${ Welomebackground })` }}>
            <Container fluid className='unauthorized-page'>
                <div className='unauthorized-text'>
                    <h1 style={{ marginBottom: '20px' }}>Welcome to the ProConnect Application</h1>
                    {props && props.welcome ? 
                        <div>
                            <p style={{ fontSize: '15px' }}> ProConnect is a web application dashboard that aggregates important and relevant information about our clients and their companies to develop meaningful client relationships</p>
                            <p style={{ fontSize: '15px' }}>Please search a client or company</p>
                            <p style={{ fontSize: '15px' }}>Quotation marks, " ", should ideally be used if you want the search engine to only look for sites with exact phrasing.</p>
                        </div>
                        :
                        <p style={{ fontSize: '15px' }}>Unfortunately you do not have access to this application. If you should have access to this site or would like access, please contact the <a className='text-primary' href={mailToSupport()}>ProConnect Support Team</a>.</p>
                    }
                </div>
                <div className='protiviti-logo-div'>
                    <img className='protiviti-logo-background' src={protivitiLogo} alt="Protiviti" />
                </div>
            </Container>  
        </div>
    );
}