import React, { useEffect, useContext, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import './Overview.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BootstrapTable from 'react-bootstrap-table-next';
import { AssessmentOutlined } from '@material-ui/icons';
import { NotAvailable } from '../NotAvailable';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { formatDate, renderSortArrow, triggerFilter, handleOnFilterTable, getOnStaticOptionsUpdate } from '../Shared/Util';
import { FilterMultiSelect } from '../Shared/FilterMultiSelect';
import filterFactory, { customFilter, FILTER_TYPES } from 'react-bootstrap-table2-filter';

export const MarketingCampaigns = () => {
    const { prospect } = useContext(GlobalContext);
    const [showMore, setShowMore] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [showFiltersModal, setShowFiltersModal] = useState(true);
    const [currentFilters, setCurrentFilters] = useState({});
    const [staticOption, setStaticOption] = useState({});
    const [filteredData, setFilteredData] = useState([]);

    let typeFilter, subTypeFilter, nameFilter, sentFilter, statusFilter;

    useEffect(() => {
        if (prospect) {
            setFilteredData(prospect.campaigns);
            if (prospect.campaigns && prospect.campaigns.length < 2) {
                setShowFilters(!showFilters)
            }
        }
    }, [prospect]);

    const clearAllFilters = () => {
        typeFilter([]);
        subTypeFilter([]);
        nameFilter([]);
        sentFilter([]);
        statusFilter([]);

        setCurrentFilters({});
        setStaticOption({});
    }

    const onStaticOptionsUpdate = (staticColumn, filterKey, noFilters) => {
        if (noFilters || !staticOption.hasOwnProperty(filterKey))
            setStaticOption(getOnStaticOptionsUpdate(staticColumn, filterKey, noFilters, staticOption));
    }

    const afterFilter = (newResult, newFilters) => {
        setCurrentFilters(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(newFilters)) {
                return newFilters
            }
            return prevData
        });

        setFilteredData(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(newResult)) {
                return newResult
            }
            return prevData
        });
    }

    const tableColumns = [
        {
            dataField: 'campaignType',
            text: 'Campaign Type',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ? value : <NotAvailable notavailable='field' />),
            headerStyle: { fontWeight: '500', width: '105px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    typeFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'campaignType')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={prospect.campaigns} filterKey={'campaignType'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'campaignSubType',
            text: 'Campaign Sub-Type',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ? value : <NotAvailable notavailable='field' />),
            headerStyle: { fontWeight: '500', width: '110px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    subTypeFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'campaignSubType')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={prospect.campaigns} filterKey={'campaignSubType'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'campaignName',
            text: 'Campaign Name',
            formatter: (value, row) => (value ?
                    row.hyperLink ?
                    <Card.Link className='text-primary' href={row.hyperLink} target='_blank' rel='noopener noreferrer'>{value}</Card.Link>
                    : value
                : <NotAvailable notavailable='field' />),
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '300px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    nameFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'campaignName')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={prospect.campaigns} filterKey={'campaignName'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'campaignDateSent',
            text: 'Date Campaign Was Sent',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row, rowIndex) => ((value && value.substring(0, 4) !== '0001') ? formatDate(value) : <NotAvailable notavailable='field' />),
            headerStyle: { fontWeight: '500', width: '130px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    sentFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'campaignDateSent')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={prospect.campaigns} filterKey={'campaignDateSent'} showFilters={showMore ? showFiltersModal : showFilters} format={'date'} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (value ? value : <NotAvailable notavailable='field' />),
            headerStyle: { fontWeight: '500', width: '160px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    statusFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'status')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={prospect.campaigns} filterKey={'status'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'id',
            hidden: true
        }
    ];

    const handleShowMore = () => {
        setShowMore(!showMore);
        clearAllFilters();
    }

    const showMoreModal = () => {
        return (
            <Modal show={showMore} onHide={handleShowMore} dialogClassName='custom-dialog'>
                <Modal.Header closeButton className='card-style' style={{ borderTopColor: '#00A28F' }}>
                    <Modal.Title>
                        <h4 className='card-title-modal'>
                            <div className='contact-name' style={{ paddingLeft: '5px' }}>{prospect.name} </div>
                            <span><AssessmentOutlined fontSize="large" /> Marketing Campaigns </span>
                            <div className='project-subtitle' style={{ width: '650px' }}>These are marketing campaigns where the Prospect took action. This data is based on a rolling 12 months.</div>
                            {prospect.campaigns && prospect.campaigns.length > 0 ? null : <NotAvailable notavailable='title' />}
                        </h4>
                    </Modal.Title>
                    <div className='trigger-filter-modal'>
                        <div className='filter-button'>
                            <div>
                                {triggerFilter(showFiltersModal, setShowFiltersModal)}
                            </div>
                            <div className='clear-filter'>
                                <div className='text-primary' onClick={clearAllFilters} style={{ visibility: showFiltersModal ? 'visible' : 'hidden' }}>
                                    Clear Filters
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ overflowX: 'auto', paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px', paddingBottom: '80px' }}>
                    {
                        <BootstrapTable
                            keyField='id'
                            data={prospect.campaigns}
                            columns={tableColumns}
                            striped={true}
                            bordered={false}
                            hover={true}
                            filter={filterFactory({ afterFilter })}
                        />
                    }
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <Card>
            <Card.Body className='card-style' style={{ borderTopColor: "#00A28F", overflowY: 'hidden', overflowX: (prospect.campaigns && prospect.campaigns.length > 0) ? 'auto' : 'none' }}>
                <div className='space-between'>
                    <h4 className='card-title'>
                        <div className='contact-name' style={{ paddingLeft: '5px' }}>{prospect.name} </div>
                        <span><AssessmentOutlined fontSize="large" /> Marketing Campaigns {prospect.campaigns && prospect.campaigns.length > 0 ? null : <NotAvailable notavailable='marketingCampaign' />}</span>
                        <div className='project-subtitle' style={{ width: '650px' }}>These are marketing campaigns where the Prospect took action. This data is based on a rolling 12 months.</div>
                        {prospect.hasOptedOutOfEmail ? <span style={{ fontSize: "15px", display: "block" }}>This prospect has opted out for receiving marketing materials at this time.</span> : null}
                    </h4>
                    {prospect.campaigns && prospect.campaigns.length > 1 ?
                        <div className='filter-button'>
                            <div>
                                {triggerFilter(showFilters, setShowFilters)}
                            </div>
                            <div className='clear-filter'>
                                <div className='text-primary' onClick={clearAllFilters} style={{ visibility: showFilters ? 'visible' : 'hidden' }}>
                                    Clear Filters
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                {
                    prospect.campaigns && prospect.campaigns.length > 0 ?
                        <div className='result-table'>
                            <BootstrapTable
                                keyField='id'
                                data={prospect.campaigns}
                                columns={tableColumns}
                                striped={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({
                                    page: 1,
                                    sizePerPage: 5,
                                    hideSizePerPage: true,
                                    hidePageListOnlyOnePage: true
                                })}
                                filter={filterFactory({ afterFilter })}
                            />
                            <Button
                                style={{ float: 'right', margin: "2%", display: prospect.campaigns.length <= 5 ? 'none' : null }}
                                onClick={handleShowMore}
                                variant="outline-secondary">
                                {'View More'}
                            </Button>
                            {showMoreModal()}
                        </div>
                        :
                        <>
                            <hr />
                            <div className='not-available'><NotAvailable notavailable='noResult' /></div>
                        </>
                }
            </Card.Body>
        </Card>
    );
};
