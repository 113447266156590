import React, { useState, useEffect, useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import config from '../src/config';
import { authHeaders } from '../src/auth/authHeaders';
import axios from 'axios';
import { GlobalContext } from '../src/context/GlobalState';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);
    const [authorizedState, setAuthorizedState] = useState(false);
    const { setAuthorized, authentication, setIsProspectPage, setUserEmail } = useContext(GlobalContext);
    let location = useLocation();

    useEffect(() => {
        if (location) {
            setIsProspectPage(location.pathname.includes('/prospect/'));
        }
    }, [location]);

    useEffect(() => {
        if (authentication !== null) {
            getUser();
        }
    }, [authentication]);

    const getUser = async () => {
        let url = config.azRedirectUri + '/api/user';
        let headers = await authHeaders();
        await axios.get(url, headers).then((response) => {
            setUserEmail(response.data);
            setAuthorized(true);
            setAuthorizedState(true);
            setLoaded(true);
        })
        .catch((error) => {
            // 403 Forbidden & 500
            setAuthorized(false);
            setAuthorizedState(false);
            setLoaded(true);
        });
    }

    if (!loaded) {
        return null;
    }

    return (
        <Route
            {...rest}
            render={props => {
                if (authorizedState && authentication && authentication.authenticationState === 'Authenticated') {
                    return <Component {...props} />;
                }
                else {
                    return (
                        <Redirect to={{
                            pathname: '/unauthorized',
                            state: {
                                authData: authentication
                            }
                        }} />
                    )
                }
            }}
        />    
    );
}