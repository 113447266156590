import { authProvider } from './authProvider';

export const authHeaders = async () => {
    let getCookie = cname => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    let token = await authProvider.getAccessToken();
    let headers = { headers: { Authorization: `Bearer ${token.accessToken}`, 'Content-Type': 'application/json', 'X-XSRF-TOKEN': getCookie("X-REQUEST-TOKEN") } };

    return headers;
}