import React, { useContext, useState, useEffect } from 'react';
import '../Prospect/Overview.css';
import questionMark from '../../images/questionmark-icon.png'; // temp icon for testing
import PopoverStickOnHover from '../PopoverStickOnHover';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@material-ui/icons/ThumbDownOutlined';
import { mapValidationField } from '../Shared/Util';
import { GlobalContext } from '../../context/GlobalState';

export const ExternalDetail = (props) => {
    const { prospectValidations, accountValidations, setValidation } = useContext(GlobalContext);
    const [validationState, setValidationState] = useState(null); // thumbsup = 0, thumbsdown = 1

    useEffect(() => {
        let validation = null;
        if (props.type === 0) validation = prospectValidations;
        else if (props.type === 1) validation = accountValidations;

        if (validation !== null && validation.length > 0) {
            let found = validation.find(_ => _.field === mapValidationField(props.field, false));
            if (found && (found.state === 0 || found.state === 1))
                setValidationState(found.state);
            else
                setValidationState(null);
        }
        else {
            setValidationState(null);
        }
    }, [prospectValidations, accountValidations]);

    const handleOnClick = (state) => {
        setValidation(props.sfdcId, props.relProId, props.type, state, mapValidationField(props.field, false));
    };

    const renderThumbs = () => {
        return (
            <span>
                {validationState === 0 ?
                    <ThumbUpIcon fontSize='small' className='validation-thumb' onClick={() => handleOnClick(0)} />
                    :
                    <ThumbUpOutlinedIcon fontSize='small' className='validation-thumb' onClick={() => handleOnClick(0)} />
                }
                {validationState === 1 ?
                    <ThumbDownIcon fontSize='small' className='validation-thumb' onClick={() => handleOnClick(1)} />
                    :
                    <ThumbDownOutlinedIcon fontSize='small' className='validation-thumb' onClick={() => handleOnClick(1)} />
                }
            </span>    
        );
    };

    return (
        <PopoverStickOnHover
            placement='bottom'
            onMouseEnter={ () => { } }
            delay={200}
            component={
                <div>
                    <div>{props.data}</div>
                    <div>
                        {renderThumbs()}
                    </div>
                </div>
            }
        >
            <img src={questionMark} className='notavailable-icon' alt='Question' />
        </PopoverStickOnHover>
    );
}