import React from "react";

const DefaultPhoto = (props) => (
    <svg
        className={"icon icon-tabler icon-tabler-user " + (props && props.format === 'square' ? 'internal-connection-picture' : 'search-profile-picture')}
        alt="Profile Picture"
        width={100}
        height={100}
        viewBox="0 0 24 24"
        strokeWidth={1}
        stroke="#2c3e50"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx={12} cy={7} r={4} />
        <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
    </svg>
);

export default DefaultPhoto;