import React from 'react';
import ReactDOM from 'react-dom';
import { AzureAD } from 'react-aad-msal';
import TimerRoot from './TimerRoot';
import { authProvider } from './auth/authProvider';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(
    <AzureAD provider={authProvider} forceLogin={true}>
        {
            ({ authenticationState, accountInfo }) => <TimerRoot authenticationState={authenticationState} accountInfo={accountInfo} />
        }
    </AzureAD>,
    document.getElementById('root'),
);