import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import './CompanyOverview.css';
import Card from 'react-bootstrap/Card';
import { NotAvailable } from '../NotAvailable';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getUrlLink, formatWebsite, formatText, provideLinkToCompanyPage, formatRevenue } from '../Shared/Util';
import BusinessIcon from '@material-ui/icons/Business';
import { OverlayTooltip } from '../OverlayTooltip';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ListGroup from 'react-bootstrap/ListGroup';

export const Competitors = () => {
    const { account } = useContext(GlobalContext);
    const [companyCompetitors, setCompanyCompetitors] = useState([]);
    const [showMore, setShowMore] = useState(false);

   
    useEffect(() => {
        if (account && account.competitors && account.competitors.length !== 0) {
            setCompanyCompetitors(account.competitors);
        }
    }, [account]);

    const showCompetitors = (competitors, model) => {
        return (
            <ListGroup>
                {competitors.map((competitor, i) => (
                    <ListGroup.Item key={i} style={(i % 2 === 0) ? { backgroundColor: 'rgba(0,0,0,.05)' } : null}>
                        <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
                            <div style={{ marginRight: '15px', alignSelf: 'center', position: 'relative' }}>
                                {competitor.logo ?
                                    <img
                                        style={{ height: 'fit-content', width: '85px' }}
                                        src={competitor.logo}
                                        alt='Company Photo'
                                    />
                                    :
                                    <BusinessIcon className='profile-picture' style={{ height: '70px', width: '70px', float: 'left', borderRadius: '0%', marginBottom: '0px' }} />
                                }
                            </div>
                            <div style={{ flexGrow: '1', paddingLeft: '5px'}}>
                                {competitor.competitorSFDCAccountId && provideLinkToCompanyPage(competitor.competitorSFDCAccountId) ?
                                    <Card.Link href={encodeURI(`${/company/}${competitor.competitorSFDCAccountId}`)}>
                                        <h4 className='card-title-company-name text-primary' style={{ fontSize: 'large', marginBottom: '5px' }}>
                                            {competitor.name}
                                        </h4>
                                    </Card.Link>
                                    :
                                    <h4 className='card-title-company-name' style={{ fontSize: 'large', color: 'grey', marginBottom: '5px' }}>
                                        {competitor.name}
                                    </h4>
                                }
                                <div className='prospect-fields' style={{ marginBottom: '0px' }}>
                                    <span className='header-fields'>Website: </span>
                                    {competitor.website ?
                                        <Card.Link className='text-primary' href={formatWebsite(competitor.website)} target='_blank' rel='noopener noreferrer'>{competitor.website}</Card.Link>
                                        :
                                        <NotAvailable notavailable='field' linkUrl={getUrlLink('sfdclightningAcc', competitor.competitorSFDCAccountId)} />
                                    }
                                </div>
                                {competitor.revenue ?
                                    <div className='prospect-fields' style={{ marginBottom: '0px' }}>
                                        <span className='header-fields'>Company Revenue: </span>
                                        {formatRevenue(competitor.revenue)}
                                    </div>
                                    :
                                    null
                                }
                                {competitor.description ? 
                                    <p className='company-description' style={{ marginBottom: '0px', marginTop: '5px' }}>
                                        {(competitor.description.length > 100 && model === false) ?
                                            <OverlayTooltip tooltipText={competitor.description} hoverElement={<span>{formatText(competitor.description, 100)}</span>} imageType='none' />
                                            :
                                            competitor.description
                                        }
                                    </p>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        );
    }

    const handleShowMore = () => {
        setShowMore(!showMore);
    }

    const showMoreModal = () => {
        return (
            <Modal show={showMore} onHide={handleShowMore} dialogClassName='custom-dialog'>
                <Modal.Header closeButton className='card-style' style={{ borderTopColor: '#5D7B9A' }}>
                    <Modal.Title>
                        <div>
                            <h4 className='card-title-modal'><AccountTreeIcon fontSize="large" /> Competitors {(companyCompetitors.length > 0) ? null : <NotAvailable notavailable='title' linkUrl={getUrlLink('imanageFilter', account.id)} />}</h4>
                        </div>
                    </Modal.Title>
                    
                </Modal.Header>
                <Modal.Body style={{ overflow: 'auto', paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px' }}>
                    <div className='internal-connections-list'>
                        {showCompetitors(companyCompetitors, true)}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <Card>
            <Card.Body className='card-style' style={{ borderTopColor: '#5D7B9A', overflowY: 'hidden', overflowX: (companyCompetitors.length > 0) ? 'auto' : 'none' }}>
                <div className='space-between'>
                    <div>
                        <h4 className='card-title' style={{ paddingBottom: '0px', marginBottom: '5px', alignSelf: 'center' }}><AccountTreeIcon style={{ fontSize: '38px', marginRight: '12px' }} /> Competitors {(companyCompetitors.length > 0) ? null : <NotAvailable notavailable='title' linkUrl={getUrlLink('imanageFilter', account.id)} />}</h4>
                    </div>
                </div>
                {(companyCompetitors.length > 0) ?
                    <div className='internal-connections-list'>
                        {showCompetitors(companyCompetitors.slice(0, 5), false)}
                        <Button
                            style={{ float: 'right', margin: "2%", display: companyCompetitors.length <= 5 ? 'none' : null }}
                            onClick={handleShowMore}
                            variant="outline-secondary">
                            {'View More'}
                        </Button>
                        {showMoreModal()}
                    </div>
                    :
                    <>
                        <hr />
                        <div className='not-available'>
                            <NotAvailable notavailable='noResult' />
                        </div>
                    </>
                }
            </Card.Body>
        </Card>
    );
}


