import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { NotAvailable } from '../NotAvailable';
import { getUrlLink } from '../Shared/Util';
import { ShowInternalConnections } from '../Shared/ShowInternalConnection';


export const ConnectedColleagues = () => {

    const { account } = useContext(GlobalContext);
    const [showMore, setShowMore] = useState(false);

    const handleShowMore = () => setShowMore(!showMore);

    const showMoreModal = () => {
        return (
            <Modal show={showMore} onHide={handleShowMore} dialogClassName='custom-dialog'>
                <Modal.Header closeButton className='card-style' style={{ borderTopColor: '#5D7B9A', borderBottom: '0px' }}>
                    <Modal.Title>
                        <h4 className='card-title-modal'><PeopleOutlineIcon fontSize="large" /> Connected Colleagues </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflow: 'auto', paddingLeft: '0px', paddingRight: '0px', paddingTop: '0px' }}>
                    <div className='internal-connections-list'>
                        {ShowInternalConnections(account.connectedColleagues, 'account', account.id)}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <div>
            <Card>
                <Card.Body className='card-style' style={{ borderTopColor: "#5D7B9A" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <h4 className='card-title' style={{ alignSelf: 'center' }}>
                            <PeopleOutlineIcon fontSize="large" /> Connected Colleagues {(account.connectedColleagues && account.connectedColleagues.length > 0) ? null : <NotAvailable notavailable='title' linkUrl={getUrlLink('sfdclightningAcc', account.id)} />}
                            <div className='solution-subtitle' style={{ paddingBottom: '0px' }}> Based on Salesforce events from the previous week </div>
                        </h4>
                        
                    </div>
                    {(account.connectedColleagues && account.connectedColleagues.length > 0) ?
                        <div className='internal-connections-list'>
                            {ShowInternalConnections(account.connectedColleagues.slice(0, 3), 'account', account.id)}
                            <Button
                                style={{ float: 'right', margin: "2%", display: account.connectedColleagues.length <= 3 ? 'none' : null }}
                                variant="outline-secondary"
                                onClick={handleShowMore}>
                                {'View More'}
                            </Button>
                            {showMoreModal()}
                        </div>
                        :
                        <>
                            <hr />
                            <div className='not-available'>Based on our input, no one from your company has been connecting with contacts from this company.</div>
                        </>
                    }
                </Card.Body>
            </Card>
        </div>
    );
};