import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import "regenerator-runtime/runtime";

// Msal Configurations
const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/16532572-d567-4d67-8727-f12f7bb6aed3',
        clientId: '8f9b2257-a7cd-4bd5-b02c-32ed0d035d37',
        postLogoutRedirectUri: window.location.origin,  
        redirectUri: window.location.origin,
        validateAuthority: true,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

// Authentication Parameters
const authenticationParameters = {
    scopes: ["8f9b2257-a7cd-4bd5-b02c-32ed0d035d37/.default"]
}

// Options
const options = {
    loginType: LoginType.Redirect
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)