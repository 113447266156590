import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import './Overview.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { withRouter, useHistory } from 'react-router-dom';
import { InternalConnections } from './InternalConnections';
import { ProspectBackground } from './ProspectBackground';
import { CompanyDescription } from '../Shared/CompanyDescription';
import { ProtivitiWork } from '../Shared/ProtivitiWork';
import { ProtivitiWorkSolution } from '../Shared/ProtivitiWorkSolution';
import { Opportunity } from '../Shared/Opportunity';
import { CompanyNews } from '../Shared/CompanyNews';
import { KeyBuyerHistory } from './KeyBuyerHistory';
import { MarketingCampaigns } from './MarketingCampaigns';
import { MarketingSolution } from '../Shared/MarketingSolution';
import { ScoopsIntents } from '../Shared/ScoopsIntents';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

export const Overview = withRouter((props) => {
    const { prospect, getProspect, getValidation } = useContext(GlobalContext);
    const [prevPathSearch, setPrevPathSearch] = useState(false);
    const [prevPathReturnHome, setPrevPathReturnHome] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [marketingSolutionData, setMarketingSolutionData] = useState(null);
    const history = useHistory();

    useEffect(() => {
        setLoaded(false);
        fetchData();

        if (props.location.state && props.location.state.prevPath.pathname === '/search') {
            setPrevPathSearch(true);
            setPrevPathReturnHome(false);
        }
        else if (props.location.state && props.location.state.prevPath.pathname.includes('/connections')) {
            setPrevPathReturnHome(true);
            setPrevPathSearch(false);
        }
        else {
            setPrevPathSearch(false);
            setPrevPathReturnHome(false);
        }
    }, [props.location]);

    useEffect(() => {
        if (prospect) {
            let marketingSolution = {
                id: prospect.id,
                marketingSolutionA: prospect.marketingSolutionA,
                marketingSolution1Year: prospect.marketingSolution1Year,
                campaignsActionsA: prospect.campaignsActionsA
            };
            setMarketingSolutionData(marketingSolution);

            if (prospect.id && prospect.relProContactId) {
                getValidation(prospect.id, prospect.relProContactId, 0);
            }
        }
    }, [prospect]);

    const fetchData = async () => {
        const { match: { params } } = props; //get params
        await getProspect(params.prospectId);
        setLoaded(true);
    }

    const goBackPrevSearch = () => {
        props.history.push({
            pathname: props.location.state.prevPath.pathname,
            search: props.location.state.prevPath.search,
            state: { returnbutton: true }
        });
    }

    const goBackHome = () => {
        props.history.push({
            pathname: '/'
        });
    }

    if (!prospect && loaded) {
        return (
            <Container fluid className='search-result'>
                <Row className='row-results'>
                    <div className='no-result'>This record does not exist or has since been removed from the ProConnect system. Please visit<a href='https://protiviti.lightning.force.com/one/one.app' target='_blank' rel='noopener noreferrer'> Salesforce</a><span> to add this record back into the system.</span></div>
                </Row>
            </Container>
            
        );
    }
    // renders nothing until data is loaded
    if (!loaded) {
        return null;
    }

    return (
        <Container fluid className='overview-style'>
            {history && history.length > 1 && history.action !== 'POP' ?
                prevPathSearch ?
                    <div className='back-button' onClick={goBackPrevSearch}>
                        <DoubleArrowIcon className='back-arrow' />
                        <span className='back-text'>Return to Search</span>
                    </div>
                    :
                    <div className='back-button' onClick={prevPathReturnHome ? goBackHome : history.goBack}>
                        <DoubleArrowIcon className='back-arrow' />
                        <span className='back-text'>Return to Previous Page</span>
                    </div>
                :
                null
            }

            <Row>
                <Col lg={4}>
                    <ProspectBackground />
                </Col>
                <Col>
                    <CompanyDescription />
                </Col>
            </Row>
            <Row>
                <Col>
                    <InternalConnections />
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <ProtivitiWork />
                </Col>
                <Col lg={4}>
                    <ProtivitiWorkSolution />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Opportunity />
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <ScoopsIntents />
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <KeyBuyerHistory />
                </Col>
                <Col lg={4}>
                    <CompanyNews view="prospect" />
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <MarketingCampaigns />
                </Col>
                <Col lg={4}>
                    <MarketingSolution marketingSolutionData={marketingSolutionData} />
                </Col>
            </Row>
        </Container>
    );
});