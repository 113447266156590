import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import axios from 'axios';
import config from '../config';
import { authHeaders } from '../auth/authHeaders';
import { formatAccountName } from '../../src/components/Shared/Util';

// Initial state
const initialState = {
    prospect: null,
    newsFeed: [],
    searchSetting: {},
    searchFacet: null,
    following: [],
    followingAccounts: [],
    updatedFollowing: [],
    updatedFollowingAccounts: [],
    contactOwner: [],
    relationshipLead: [],
    account: null,
    authorized: false,
    authentication: null,
    isProspectPage: false,
    taggedRelationships: [],
    userEmail: null,
    scoops: null,
    intents: null,
    prospectValidations: [],
    accountValidations: []
}

// Create context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = (props) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    // Actions
    const getProspect = async (id) => {
        let headers = await authHeaders();
        let prospectUrl = config.azRedirectUri + '/api/prospects/' + id;

        await axios.get(prospectUrl, headers).then((response) => {
            let companyName = null;

            if (response && response.data && response.data.company && response.data.company.name) {
                companyName = formatAccountName(response.data.company.name);
                response.data.company.name = companyName;

                dispatch({
                    type: 'GET_ACCOUNT',
                    payload: response.data.company
                });
            }

            if (response && response.data && response.data.primaryKeyBuyerOf && response.data.primaryKeyBuyerOf.length > 0) {
                response.data.primaryKeyBuyerOf.map((opp) => {
                    opp.companyName = formatAccountName(opp.companyName);
                });
            }

            dispatch({
                type: 'GET_PROSPECT',
                payload: response.data
            });
            if (response.data.company.relProAccountId)
                getScoopsIntents(response.data.company.relProAccountId);
            if (response.data.company && response.data.company.tickerSymbol)
                getCompanyNews(response.data.company.tickerSymbol, companyName);

        }).catch((err) => {
            dispatch({
                type: 'GET_PROSPECT',
                payload: null
            });
            dispatch({
                type: 'GET_ACCOUNT',
                payload: null
            });
        });
    }

    const getAccount = async (accountId) => {
        let headers = await authHeaders();
        let url = config.azRedirectUri + '/api/accounts/' + accountId;

        await axios.get(url, headers).then((response) => {
            let companyName = null;

            if (response && response.data && response.data.name) {
                companyName = formatAccountName(response.data.name);
                response.data.name = companyName;
            }

            dispatch({
                type: 'GET_ACCOUNT',
                payload: response.data
            });
            if (response.data.relProAccountId)
                getScoopsIntents(response.data.relProAccountId);
            if (response.data.tickerSymbol)
                getCompanyNews(response.data.tickerSymbol, companyName);
        }).catch((err) => {
            dispatch({
                type: 'GET_ACCOUNT',
                payload: null
            });
        });
    }

    const getCompanyNews = async (tickerSymbol, companyName) => {
        if (tickerSymbol) {
            let ticker = tickerSymbol ? '%20' + encodeURIComponent(tickerSymbol) : '';
            let url = 'https://www.bing.com/news/search?q=' + encodeURIComponent('"' + companyName + '"') + ticker + '&format=RSS';
            var yql = 'https://api.rss2json.com/v1/api.json?rss_url=' + encodeURIComponent(url);

            let news = await getCompanyNewsCall(yql);

            if (news.length === 0) {
                ticker = tickerSymbol ? '%20' + encodeURIComponent('"' + tickerSymbol + '"') : '';
                url = 'https://www.bing.com/news/search?q=' + encodeURIComponent(companyName) + ticker + '&format=RSS';
                yql = 'https://api.rss2json.com/v1/api.json?rss_url=' + encodeURIComponent(url);
                news = await getCompanyNewsCall(yql);
            }

            dispatch({
                type: 'GET_NEWSFEED',
                payload: news
            });
        }
        else {
            dispatch({
                type: 'GET_NEWSFEED',
                payload: []
            });
        }
    }

    const getCompanyNewsCall = async (yql) => {
        return await axios.get(yql)
            .then(function (response) {
                let data = response.data;
                let feed = [];
                feed = data.items.sort(function (a, b) {
                    if (a["pubDate"] > b["pubDate"])
                        return -1;
                    else if (a["pubDate"] < b["pubDate"])
                        return 1;
                    return 0;
                })
                feed = feed.filter((v, i, a) => a.findIndex(a => (a["title"] === v["title"])) === i).splice(0, 10);
                if (feed.length > 0) {
                    for (let i = 0; i < feed.length; i++) {
                        feed[i].link = feed[i].link.replace(new RegExp('amp;', 'g'), '');
                        feed[i].title = feed[i].title.replace(new RegExp('amp;', 'g'), '');
                    }
                }

                return feed;
            })
            .catch(error => {
                dispatch({
                    type: 'GET_NEWSFEED',
                    payload: []
                });
                return [];
            });
    }

    const getScoopsIntents = async (relProAccountId) => {

        let relProId = relProAccountId ? relProAccountId.replace('/', '%2F') : null
        let headers = await authHeaders();
        let scoopUrl = config.azRedirectUri + '/api/Scoop';
        let intentUrl = config.azRedirectUri + '/api/Intent';
        await Promise.all([axios.post(scoopUrl, { accountId: relProId }, headers), axios.post(intentUrl, { accountId: relProId }, headers)])
            .then((responses) => {
                const [scoopsResponse, intentsResponse] = responses;
                if (scoopsResponse.data.length > 0) {
                    dispatch({
                        type: 'GET_SCOOPS',
                        payload: scoopsResponse.data
                    })
                }
                if (intentsResponse.data.length > 0) {
                    dispatch({
                        type: 'GET_INTENTS',
                        payload: intentsResponse.data
                    })
                }
            })
            .catch((err) => {
                dispatch({
                    type: 'GET_SCOOPS',
                    payload: null
                });
                dispatch({
                    type: 'GET_INTENTS',
                    payload: null
                })
            });
    }

    const getFollowing = async () => {
        let headers = await authHeaders();
        let url = config.azRedirectUri + '/api/user/followedprospects';

        await axios.get(url, headers)
            .then((res) => {
                dispatch({
                    type: 'GET_FOLLOWING',
                    payload: res.data
                });
            })
    }

    const followProspect = async (id) => {
        let headers = await authHeaders();
        let url = config.azRedirectUri + '/api/user/followprospect/' + id;

        await axios.post(url, null, headers)
            .then((res) => {
                getFollowing();
            })
    }

    const getContactOwner = async () => {
        let header = await authHeaders();
        let url = config.azRedirectUri + '/api/user/contactowner';

        await axios.get(url, header)
            .then((res) => {
                dispatch({
                    type: 'GET_CONTACTOWNER',
                    payload: res.data
                })
            })
    }

    const getRelationshipLead = async () => {
        let header = await authHeaders();
        let url = config.azRedirectUri + '/api/user/relationshiplead';

        await axios.get(url, header)
            .then((res) => {
                dispatch({
                    type: 'GET_RELATIONSHIPLEAD',
                    payload: res.data
                })
            })
    }

    const getFollowingAccounts = async () => {
        let headers = await authHeaders();
        let url = config.azRedirectUri + '/api/user/followedaccounts';

        await axios.get(url, headers)
            .then((res) => {
                dispatch({
                    type: 'GET_FOLLOWING_ACCOUNTS',
                    payload: res.data
                });
            })
    }

    const followAccount = async (id) => {
        let headers = await authHeaders();
        let url = config.azRedirectUri + '/api/user/followaccount/' + id;

        await axios.post(url, null, headers)
            .then((res) => {
                getFollowingAccounts();
            })
    }

    const setSearchSetting = (settings) => {
        dispatch({
            type: 'SET_SEARCH_SETTING',
            payload: settings
        });
    }

    const setSearchFacet = (facet) => {
        dispatch({
            type: 'SET_FACET',
            payload: facet
        });
    }

    const setAuthorized = (isAuthorized) => {
        dispatch({
            type: 'SET_AUTHORIZED',
            payload: isAuthorized
        });
    }

    const setUpdatedFollowing = (data) => {
        dispatch({
            type: 'SET_UPDATED_FOLLOWING',
            payload: data
        });
    }

    const setUpdatedFollowingAccounts = (data) => {
        dispatch({
            type: 'SET_UPDATED_FOLLOWING_ACCOUNTS',
            payload: data
        });
    }

    const setIsProspectPage = (prospectPage) => {
        dispatch({
            type: 'SET_PROSPECT_PAGE',
            payload: prospectPage
        });
    }

    const getTaggedRelationships = async () => {
        let header = await authHeaders();
        let url = config.azRedirectUri + '/api/user/taggedrelationships';

        await axios.get(url, header)
            .then((res) => {
                dispatch({
                    type: 'GET_TAGGEDRELATIONSHIPS',
                    payload: res.data
                });
            });
    }

    const setUserEmail = (data) => {
        dispatch({
            type: 'SET_USER_EMAIL',
            payload: data
        });
    }

    const getValidation = async (sfdcId, relProId, type) => {
        let header = await authHeaders();
        let url = `${config.azRedirectUri}/api/user/validation?sfdcId=${sfdcId}&relProId=${relProId}&type=${type}`;
        let dispatchType = type === 0 ? 'GET_PROSPECT_VALIDATIONS' : 'GET_ACCOUNT_VALIDATIONS';

        await axios.get(url, header)
            .then((res) => {
                dispatch({
                    type: dispatchType,
                    payload: res.data
                });
            });
    }

    const setValidation = async (sfdcId, relProId, type, state, field) => {
        let header = await authHeaders();
        let url = `${config.azRedirectUri}/api/user/validation/validate`;

        let body = {
            sfdcId,
            relProId,
            type,
            state,
            field
        };

        await axios.post(url, body, header)
            .then((res) => {
                let dispatchType = body.type === 0 ? 'GET_PROSPECT_VALIDATIONS' : 'GET_ACCOUNT_VALIDATIONS';
                dispatch({
                    type: dispatchType,
                    payload: res.data
                });
            });
    }

    return (<GlobalContext.Provider value={{
        prospect: state.prospect,
        newsFeed: state.newsFeed,
        searchSetting: state.searchSetting,
        searchFacet: state.searchFacet,
        getProspect,
        setSearchSetting,
        setSearchFacet,
        following: state.following,
        getFollowing,
        followProspect,
        followingAccounts: state.followingAccounts,
        getFollowingAccounts,
        followAccount,
        updatedFollowing: state.updatedFollowing,
        setUpdatedFollowing,
        updatedFollowingAccounts: state.updatedFollowingAccounts,
        setUpdatedFollowingAccounts,
        contactOwner: state.contactOwner,
        getContactOwner,
        relationshipLead: state.relationshipLead,
        getRelationshipLead,
        account: state.account,
        getAccount,
        authorized: state.authorized,
        setAuthorized,
        authentication: props.authData,
        isProspectPage: state.isProspectPage,
        setIsProspectPage,
        taggedRelationships: state.taggedRelationships,
        getTaggedRelationships,
        userEmail: state.userEmail,
        setUserEmail,
        scoops: state.scoops,
        intents: state.intents,
        prospectValidations: state.prospectValidations,
        accountValidations: state.accountValidations,
        getValidation,
        setValidation
    }}>
        {props.children}
    </GlobalContext.Provider>);
};