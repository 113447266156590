import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import '../Prospect/Overview.css';
import './CompanyOverview.css';
import Card from 'react-bootstrap/Card';
import { Grid } from '@material-ui/core';
import { VpnKeyOutlined, Assignment } from '@material-ui/icons';
import { NotAvailable } from '../NotAvailable';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DefaultPhoto from '../Shared/DefaultPhoto';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EmployeeLinks } from '../Shared/EmployeeLinks';
import filterFactory, { customFilter, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import { getUrlLink, formatDate, formatMonthYearDate, renderSortArrow, triggerFilter, handleOnFilterTable, asteriskHoverover, getOnStaticOptionsUpdate } from '../Shared/Util';
import { FilterMultiSelect } from '../Shared/FilterMultiSelect';

export const KeyBuyer = () => {
    const { account } = useContext(GlobalContext);
    const [keyBuyers, setKeyBuyers] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [showFilters, setShowFilters] = useState(true);
    const [showFiltersModal, setShowFiltersModal] = useState(true);
    const [clientSideSettings] = useState(JSON.parse(localStorage.getItem('ClientSideSettings')));
    const [currentFilters, setCurrentFilters] = useState({});
    const [staticOption, setStaticOption] = useState({});
    const [filteredData, setFilteredData] = useState([]);

    let nameFilter, titleFilter, winsFilter, lastWonFilter;

    // # of Wins
    const [rowData, setRowData] = useState(null);
    const [numberOfWins, setNumberOfWins] = useState([]);
    const [modalData, setModalData] = useState(null);
    const [showWins, setShowWins] = useState(false);
    const [showFiltersWins, setShowFiltersWins] = useState(true);
    const [currentFiltersWins, setCurrentFiltersWins] = useState({});
    const [staticOptionWins, setStaticOptionWins] = useState({});
    const [filteredDataWins, setFilteredDataWins] = useState([]);

    let winNameFilter, solutionFilter, dateWonFilter;

    useEffect(() => {
        if (account && account.keyBuyers && account.keyBuyers.length !== 0) {
            setKeyBuyers(account.keyBuyers);
            setFilteredData(account.keyBuyers);

            if (account.keyBuyers.length < 2) {
                setShowFilters(!showFilters)
            }
        }
    }, [account]);

    useEffect(() => {
        if (rowData) {
            let winData = rowData.closeWonOpps ? rowData.closeWonOpps.sort((a, b) => a.opportunityCloseDate < b.opportunityCloseDate ? 1 : -1) : [];
            setNumberOfWins(winData);

            let data = {
                prospectName: rowData.name || '',
                numOfWin: rowData.numberOfWins || 0,
                solutionTotal: winData && winData.length > 0 ? winData.reduce((map, d) => ({ ...map, [d.solution]: (map[d.solution] || 0) + 1, }), {}) : null
            };
            setModalData(data);
        }
        else {
            setNumberOfWins([]);
            setModalData(null);
        }
    }, [rowData]);

    const clearAllFiltersPKB = () => {
        nameFilter([]);
        titleFilter([]);
        winsFilter([]);
        lastWonFilter([]);
        setCurrentFilters({});
        setStaticOption({});
    }

    const clearAllFiltersWins = () => {
        winNameFilter([]);
        solutionFilter([]);
        dateWonFilter([]);
        setCurrentFiltersWins({});
        setStaticOptionWins({});
    }

    const onStaticOptionsUpdate = (staticColumn, filterKey, noFilters) => {
        if (noFilters || !staticOption.hasOwnProperty(filterKey))
            setStaticOption(getOnStaticOptionsUpdate(staticColumn, filterKey, noFilters, staticOption));
    }

    const afterFilter = (newResult, newFilters) => {
        setCurrentFilters(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(newFilters)) {
                return newFilters
            }
            return prevData
        });

        setFilteredData(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(newResult)) {
                return newResult
            }
            return prevData
        });
    }

    const onStaticOptionsUpdateWins = (staticColumn, filterKey, noFilters) => {
        if (noFilters || !staticOptionWins.hasOwnProperty(filterKey))
            setStaticOptionWins(getOnStaticOptionsUpdate(staticColumn, filterKey, noFilters, staticOptionWins));
    }

    const afterFilterWins = (newResult, newFilters) => {
        setCurrentFiltersWins(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(newFilters)) {
                return newFilters
            }
            return prevData
        });

        setFilteredDataWins(prevData => {
            if (JSON.stringify(prevData) !== JSON.stringify(newResult)) {
                return newResult
            }
            return prevData
        });
    }

    const tableColumn = [
        {
            dataField: 'photoUrl',
            text: '',
            sort: false,
            formatter: (value, row) => (
                value ?
                    <img
                        className='internal-connection-picture'
                        src={value}
                        alt='Profile'
                        style={{ borderColor: '#00acc4', width: '60px', height: '60px' }}
                    />
                    :
                    <DefaultPhoto format='square' style={{ borderColor: '#00acc4', width: '60px', height: '60px' }} />
            ),
            headerStyle: { fontWeight: '500', width: '90px' }
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (
                <div>
                    <div>
                        {row.pkbIsNotProspect ? asteriskHoverover(value, true) : <Link className='card-title-company-name' style={{ paddingBottom: '10px' }} to={`/prospect/${row.id}`}>{value}</Link>}
                    </div>
                    {!row.isDeleted ? <EmployeeLinks data={row} isPeopleSearchLive={clientSideSettings.isPeopleSearchLive} peopleSearchUrl={encodeURI(clientSideSettings.peopleSearchUrl)} external={true} /> : null}
                </div>
            ),
            headerStyle: { fontWeight: '500', width: '190px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    nameFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'name')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={keyBuyers} filterKey={'name'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'title',
            text: 'Title',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (
                value ? value : <NotAvailable notavailable='no-update' />
            ),
            headerStyle: { fontWeight: '500', width: '250px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    titleFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'title')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={keyBuyers} filterKey={'title'} showFilters={showMore ? showFiltersModal : showFilters} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'numberOfWins',
            text: '# of Wins',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (
                <div className='text-primary' onClick={() => handleNumberOfWins(row)}>{value}</div>
            ),
            headerStyle: { fontWeight: '500', width: '150px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    winsFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'numberOfWins')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={keyBuyers} filterKey={'numberOfWins'} showFilters={showMore ? showFiltersModal : showFilters} format={'number'} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'lastOpportunityWonDate',
            text: 'Last Opportunity Won Date',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (
                (value && value.substring(0, 4) !== '0001') ? formatDate(value) : <NotAvailable notavailable='field' linkUrl={getUrlLink(row.opportunityKey ? 'iManage' : 'sfdclightningOpp', row.opportunityKey ? row.opportunityKey : row.id)} />
            ),
            headerStyle: { fontWeight: '500', width: '250px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    lastWonFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'lastOpportunityWonDate')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={keyBuyers} filterKey={'lastOpportunityWonDate'} showFilters={showMore ? showFiltersModal : showFilters} format={'date'} currentFilters={currentFilters} staticOption={staticOption} onStaticOptionsUpdate={onStaticOptionsUpdate} filteredData={filteredData} />
        },
        {
            dataField: 'id',
            text: 'key',
            hidden: true
        }
    ];

    const keyBuyerColumn = [
        {
            dataField: 'name',
            text: 'Win Name',
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '350px' },
            formatter: (value, row) => (
                <span>
                    {row.opportunityKey ?
                        <Card.Link className='text-primary' href={encodeURI(clientSideSettings.iManageUrl + row.opportunityKey)} target='_blank' rel='noopener noreferrer'>{value}</Card.Link>
                        :
                        <Card.Link className='text-primary' href={encodeURI(clientSideSettings.sfdcLightningOpportunity + row.id + '/view')} target='_blank' rel='noopener noreferrer'>{value}</Card.Link>
                    }
                </span>
            ),
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    winNameFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'name')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={numberOfWins} filterKey={'name'} showFilters={showFiltersWins} currentFilters={currentFiltersWins} staticOption={staticOptionWins} onStaticOptionsUpdate={onStaticOptionsUpdateWins} filteredData={filteredDataWins} from={'key-buyer'} />
        },
        {
            dataField: 'solution',
            text: 'Solution',
            sort: true,
            sortCaret: renderSortArrow,
            headerStyle: { fontWeight: '500', width: '150px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    solutionFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'solution')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={numberOfWins} filterKey={'solution'} showFilters={showFiltersWins} currentFilters={currentFiltersWins} staticOption={staticOptionWins} onStaticOptionsUpdate={onStaticOptionsUpdateWins} filteredData={filteredDataWins} from={'key-buyer'} />
        },
        {
            dataField: 'opportunityCloseDate',
            text: 'Date Won',
            sort: true,
            sortCaret: renderSortArrow,
            formatter: (value, row) => (
                value ? formatMonthYearDate(value.substring(0, 10)) : <NotAvailable notavailable='field' />
            ),
            headerStyle: { fontWeight: '500', width: '150px' },
            filter: customFilter({
                type: FILTER_TYPES.MULTISELECT,
                getFilter: (filter) => {
                    dateWonFilter = filter;
                },
                onFilter: (filterArr, data) => handleOnFilterTable(filterArr, data, 'opportunityCloseDate')
            }),
            filterRenderer: (onFilter, column) => <FilterMultiSelect onFilter={onFilter} column={column} options={numberOfWins} filterKey={'opportunityCloseDate'} showFilters={showFiltersWins} format={'date-month-year'} currentFilters={currentFiltersWins} staticOption={staticOptionWins} onStaticOptionsUpdate={onStaticOptionsUpdateWins} filteredData={filteredDataWins} from={'key-buyer'} />
        },
        {
            dataField: 'id',
            text: 'key',
            hidden: true
        }
    ];

    const padZero = (e) => {
        return (e < 10) ? ("0" + e) : e;
    }

    const handleShowMore = () => {
        setShowMore(!showMore);
        clearAllFiltersPKB();
    }

    const handleNumberOfWins = (e) => {
        setShowWins(!showWins);
        setShowMore(false);

        setCurrentFiltersWins({});
        setStaticOptionWins({});

        setFilteredDataWins(e ? e.closeWonOpps : []);
        setRowData(e ? e : null);
    }

    const showNumberOfWins = () => {
        if (!numberOfWins || numberOfWins.length === 0 || !modalData) return null;

        return (
            <Modal show={showWins} onHide={handleNumberOfWins} dialogClassName='number-wins-dialog' data-testid='keybuyer-wins-modal'>
                <Modal.Header className='modal-header-overflow card-style' closeButton style={{ borderTopColor: '#5D7B9A' }}>
                    <Modal.Title>
                        <div className='space-between'>
                            <h4 className='number-wins-title-modal'>
                                <Assignment fontSize='large' /><span className='number-wins-modal-icon'>{modalData.numOfWin} Wins for {modalData.prospectName}</span>
                            </h4>
                        </div>
                    </Modal.Title>
                    <div className='trigger-filter-modal'>
                        <div className='filter-button'>
                            <div>
                                {triggerFilter(showFiltersWins, setShowFiltersWins)}
                            </div>
                            <div className='clear-filter'>
                                <div className='text-primary' onClick={clearAllFiltersWins} style={{ visibility: showFiltersWins ? 'visible' : 'hidden' }}>
                                    Clear Filters
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='modal-body-class'>
                    {modalData.solutionTotal ?
                        <div className='solution-total'>
                            <Grid container spacing={1}>
                                {Object.entries(modalData.solutionTotal).map(([key, value]) => (
                                    <Grid item xs={3} key={key}>
                                        <div className='solution-total-key'>{key}</div>
                                        <div className='solution-total-value'>{padZero(value)}</div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                        :
                        null
                    }
                    {
                        <BootstrapTable
                            keyField='id'
                            data={numberOfWins}
                            columns={keyBuyerColumn}
                            striped={true}
                            bordered={false}
                            hover={true}
                            filter={filterFactory({ afterFilter: afterFilterWins })}
                        />
                    }
                </Modal.Body>
            </Modal>
        )
    }

    const showMoreModal = () => {
        return (
            <Modal show={showMore} onHide={handleShowMore} dialogClassName='custom-dialog' data-testid='keybuyer-table-modal'>
                <Modal.Header closeButton className='card-style' style={{ borderTopColor: '#5D7B9A' }}>
                    <Modal.Title>
                        <div>
                            <h4 className='card-title-modal'><VpnKeyOutlined fontSize="large" /> Key Buyers {(keyBuyers.length > 0) ? null : <NotAvailable notavailable='title' linkUrl={getUrlLink('imanageFilter', account.id)} />}</h4>
                            <div className='solution-subtitle-modal'>Based on Won opportunities over a rolling 5 years.</div>
                        </div>
                    </Modal.Title>
                    <div className='trigger-filter-modal'>
                        <div className='filter-button'>
                            <div>
                                {triggerFilter(showFiltersModal, setShowFiltersModal)}
                            </div>
                            <div className='clear-filter'>
                                <div className='text-primary' onClick={clearAllFiltersPKB} style={{ visibility: showFiltersModal ? 'visible' : 'hidden' }}>
                                    Clear Filters
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='modal-body-class'>
                    {
                        <BootstrapTable
                            keyField='id'
                            data={keyBuyers}
                            columns={tableColumn}
                            striped={true}
                            bordered={false}
                            hover={true}
                            pagination={paginationFactory({
                                page: 1,
                                sizePerPage: 25,
                                hideSizePerPage: true,
                                hidePageListOnlyOnePage: true
                            })}
                            filter={filterFactory({ afterFilter })}
                        />
                    }
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <Card data-testid='keybuyer'>
            <Card.Body className='card-style' style={{ borderTopColor: '#5D7B9A', overflowY: 'hidden', overflowX: (keyBuyers.length > 0) ? 'auto' : 'none' }}>
                <div className='space-between'>
                    <div>
                        <h4 className='card-title' style={{ paddingBottom: '0px', marginBottom: '0px', alignSelf: 'center' }}><VpnKeyOutlined fontSize="large" /> Key Buyers {(keyBuyers.length > 0) ? null : <NotAvailable notavailable='title' linkUrl={getUrlLink('imanageFilter', account.id)} />}</h4>
                        <div className='solution-subtitle'>Based on Won opportunities over a rolling 5 years.</div>
                    </div>
                    {keyBuyers.length > 1 ? 
                        <div className='filter-button'>
                            <div>
                                {triggerFilter(showFilters, setShowFilters)}
                            </div>
                            <div className='clear-filter'>
                                <div className='text-primary' onClick={clearAllFiltersPKB} style={{ visibility: showFilters ? 'visible' : 'hidden' }}>
                                    Clear Filters
                                </div>
                            </div>
                        </div>
                    : null}
                </div>
                {(keyBuyers.length > 0) ?
                    <div className='result-table' data-testid='keybuyer-table'>
                        <BootstrapTable
                            keyField='id'
                            data={keyBuyers}
                            columns={tableColumn}
                            striped={true}
                            bordered={false}
                            hover={true}
                            pagination={paginationFactory({
                                page: 1,
                                sizePerPage: 5,
                                hideSizePerPage: true,
                                hidePageListOnlyOnePage: true
                            })}
                            filter={filterFactory({ afterFilter })}
                        />
                        <Button
                            style={{ float: 'right', margin: "2%", display: keyBuyers.length <= 5 ? 'none' : null }}
                            onClick={handleShowMore}
                            variant="outline-secondary">
                            {'View More'}
                        </Button>
                        {showMoreModal()}
                        {showNumberOfWins()}
                    </div>
                    :
                    <>
                        <hr />
                        <div className='not-available'>
                            <NotAvailable notavailable='noResult' />
                        </div>
                    </>
                }
            </Card.Body>
        </Card>
    );
}